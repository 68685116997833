.vc {
    .container {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0px;
    }

    .row{
        margin: unset;
        .col-6{
            padding: 0 5px;
        }
    }
    
    .participant p {
        white-space: nowrap;
        // width: 150px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        margin: 0px;
    }
    .participant div:first-child {
        background-color: lightgrey;
        // width: 150px;
        height: 112.5px;
        display: flex;
    }
    .participant video {
        width: 100%;
        height: 100%;
    }

    .mute {
        video {
            visibility: hidden;
        }
    }

    // .participant div video:not(.participantZoomed) {
    //     width: 150px;
    // }
    .participant .label {
        background-color: #ddd;
    }
    .participantZoomed {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    .participantHidden {
        display: none;
    }

    .controls {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0px 8px 8px 8px;

        div {
            display: inline-block;
            padding: 8px;
            cursor: pointer;
        }
    }
}
