.whiteboard-container {
    height: 92%;
    width: 100.7%;
    display: flex;
}

.answer-board-holder {
    height: 100%;
    width: 100%;
    display: flex !important;
}

.step {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    margin-right: 15px;


    .problemStatementBox {
        width: 32%;
        height: 96%;
        overflow-y: auto;
        position: relative;
        float: left;
        margin-right: 5px;
        overflow-x: hidden;
    }

    .showHideProblemStatementContainer {
        display: flex;
        align-items: center;
        margin-right: 10px;
        border-right: 1px solid #ccc;

        button {
            height: 44px;
        }
    }

    .stepByStepContainer {
        width: 67%;
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        overflow-x: hidden;

        .expandCollapseLinks {
            display: flex;
            justify-content: flex-end;

            span {
                margin-left: 10px;
                color: blue;
                text-decoration: underline;
                cursor: pointer;
            }

        }

        .stepByStepContainerSteps {
            overflow-y: auto;
            float: left;
            height: 88%;
            margin-bottom: 10px;
        }

        .hintContainer {
            display: flex;
            justify-content: flex-end;

            span {
                color: blue;
                text-decoration: underline;
                cursor: pointer;
                margin-left: 10px;
            }
        }

        .helpButtons {
            top: 88%;
            height: 60px;
            background-color: #f8f8f8;
            width: 100%;
            position: sticky;
            border: 1px solid #f5f5f7;
            padding-left: 10px;
            border-radius: 10px;

        }
    }



    .nextStepLinkButton {
        color: #4252AF;
        font-size: 17px;
        font-style: italic;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 5px;
    }

    .commonItems {
        display: flex;
        justify-content: space-between;
    }

}

.aiTutorContainer {
    position: absolute;
    right: 10px;
}

.canvas {
    width: 99% !important;
}

.helpBtns {
    padding: 8px;
    margin-right: 5px;
}

.helpCompBtns {
    position: fixed;
    top: 89%;
    margin: 5px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
}

.ResizeHandleInner {
    .Icon {
        top: 360px;
    }
}