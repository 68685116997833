.withasteriskafter{

}
.withasteriskafter::after {
    content: "*";
    
}

.withasteriskbefore{

}
.withasteriskbefore::before {
    content: "*";
    
}