
.bookmark-container {
  //overflow-x: hidden;
  //background-color: #fff;
  max-width: 96% !important;
}
.mynotes-left {
  float: left;
  width: 300px;
  padding: 30px 20px;
  background-color: #ebf1ff;
  color: #303030;
  overflow: hidden;
  .divflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .titletreeview {
    font-size: 22px;
    color: #212529;
    text-decoration: none solid rgb(33, 37, 41);
    line-height: 32px;
  }

  .clear-filter {
    cursor: pointer;
    text-decoration: none solid rgb(33, 37, 41);
    font-size: 14px;
    color: #0080ff;
    line-height: 1;
    text-align: right;
    padding-top: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.mynotes-right {
  float: left;
  width: calc(100% - 300px);
  padding: 10px 20px 0px 20px;
  color: #303030;

  .column-tabs {
    width: 100%;
    text-align: right;   
    list-style: none;
    margin-bottom: 0;

    li {
      text-align: right;
      display: inline-block;
      //height: 39px;

      a {
        padding: 8px;
        text-decoration: none;
        font-size: 14px;
        color: #444444;
        line-height: 24px;
        display: flex;
        &:active{
          background-color: #ffffff;
        }
        img {
          margin-right: 7px;
        }
      }
    }
  }
}