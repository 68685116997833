@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.user-progress {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;

  .user-progress-anchor {
    color: #fff !important;
    font-size: 10px;
    text-decoration: none;
    height: 100%;
    display: flex;

    label {
      margin: auto 0;
    }

    img {
      // margin: auto 20px;
      // width: 40px;
      // height: 40px;
      margin: auto 10px;
      width: 25px;
      height: 25px;
    }
  }

  &:hover {
    .user-progress-anchor {
      cursor: pointer;
      text-decoration: none;
      color: #fff;
    }

    .progress-box {
      visibility: visible;
    }
  }
}

.progress-box {
  visibility: hidden;
  width: 280px;
  padding: 6px 15px;
  background-color: #fff;
  text-align: left;
  border-radius: 5px;
  position: absolute;
  z-index: 7;
  top: 45px;
  // left: -100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);

  &::after {
    content: "";
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -8px;
    right: 14px;
  }
}

@include media-breakpoint-down(lg) {
  .user-progress-label {
    display: none;
    visibility: hidden;
  }

  .progress-box {
    // left: -197px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user-progress-label {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (min-width: 1030px) and (max-width: 1440px) {
  .user-progress-label {
    display: none;
    visibility: hidden;
  }
}
