.wopContainer {
    width: 100%;
    display: flex;
    margin-top: 20px;
    height: 100%;
    
    .problemStatementBox {
        width: 35%;

        .statementContainer{
            user-select: text;
            margin-top: 10px;
        }
    }

    .anyProblemBox {
        width: 65%;
        margin-bottom: 75px;
    }
}

.btnNewStatement {
    color: #4353B0;
    background-color: transparent !important;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 500;
    text-transform: uppercase;
    line-height: 22px;
    letter-spacing: 0.46px;
    word-wrap: break-word
}

.divPBStatement {
    margin-left: 20px;
    color: black;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.15px;
    word-wrap: break-word
}

.divHeaderWOP {
    width: 100%;
    height: 100%;
    padding-top: 28px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 154px;
    background: #EDF1F5;
    box-shadow: 0px -2px 0px #CAD6E1 inset;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
    display: inline-flex
}

.divPBStatementWOP {
    padding-top: 24px;
    margin-right: 24px;
    background-color: #EDF1F5;
    padding-bottom: 15px;
}

.divBtn {
    color: #4353B0;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 27px;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.vectorLine {
    width: 96.5%;
    height: 1px;
    background: #4353B0;
}