.chatBot {
  width: 430px;
  height: 530px;
  // background-color: #fff;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  .buttonContainer {
    // width: 100%;
    //height: 50px;
    position: absolute;
    bottom: 0;
    text-align: right;
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #4252AF; // #fdbd17;
    cursor: pointer;
    // -webkit-box-shadow: 0px 0px 15px 0px #959da593;
    // -moz-box-shadow: 0px 0px 15px 0px #959da593;
    // box-shadow: 0px 0px 15px 0px #959da593;

    .minimized-view-icon {
      opacity: 1;
      transition: 0.2s ease-out;
    }

    .minimized-view-text {
      color: #fff;
      margin-left: 8px;
      opacity: 1;
      transition: 0.2s ease-out;
      transition-delay: 0.1s;
      white-space: nowrap;
    }
    .minimized-view-cross-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transition: 0.2s ease-out;
      transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      transition-delay: 0s;
      opacity: 0;
    }
  }

  .chatBotContainer {
    width: 100%;
    height: 480px;
    overflow-y: auto;
    border-radius: 10px;
    // margin: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0px 8px 24px 0px #959da543;
    -moz-box-shadow: 0px 8px 24px 0px #959da543;
    box-shadow: 0px 8px 24px 0px #959da543;
    .header {
      width: 100%;
      height: 50px;
      background-color: #4252AF;
      color: #fff;
      text-align: left;
      font-size: 24px;
      padding-left: 10px;
      align-items: center;

      .switch {
        font-size: 12px;
        padding-top: 6px;
        position: absolute;
        right: 55px;
      }
      .closeButton {
        border: none;
        outline: none;
        background: transparent;
        position: absolute;
        font-size: 16px;
        right: -8px;

        [class^="ico-"],
        [class*=" ico-"] {
          font: normal 1em/1 Arial, sans-serif;
          display: inline-block;
        }

        .ico-times:before {
          content: "\2716";
          //   color: #000000;
        }
      }
    }
    .chatContainer {
      height: 78%;
      overflow-y: auto;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 14px;

      .cautionText {
        font-size: 12px;
        font-style: italic;
      }
      .mainText {
        font-weight: 700;
        padding-bottom: 10px;
      }
      .loaderContainer {
        background-image: url("../../../assets/images/loading.gif");
        background-repeat: no-repeat;
        height: 30px;
      }
    }

    .chatTextContainer {
      // width: 100%;
    }

    .questionContainer {
      text-align: right;
      margin-top: 15px;

      span {
        background-color: #ebf1ff;
        color: #000;
        padding-right: 5px;
        padding-left: 5px;
        border-radius: 5px;
      }
    }

    .answerContainer {
      text-align: left;
      margin-top: 15px;

      .links {
        background-color: #ebf1ff;
        padding: 2px 5px 2px 5px;
        cursor: pointer;
        margin-bottom: 5px;
        width: max-content;

        img {
          width: 50px;
          height: 50px;
        }
      }
      span {
        background-color: #f8f0f1;
        color: #000;
        padding-right: 5px;
        padding-left: 5px;
        border-radius: 5px;
      }
    }

    .inputContainer {
      display: flex;
      margin-top: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .sendButton {
      cursor: pointer;
      width: 35px;
      padding-top: 3px;

      img {
        width: 100%;
      }
    }
  }
}
