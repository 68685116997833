.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* The actual popup */
  .popup .popuptext {
    visibility: hidden;
    width: 440px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 0;
    margin-left: -130px;
  }
  
//   /* Popup arrow */
//   .popup .popuptext::after {
//     content: "";
//     position: absolute;
//     top: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: #555 transparent transparent transparent;
//   }
  
  /* Toggle this class - hide and show the popup */
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  
  .popupBtn {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
  }
  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }