.theme-customization {
    height: 100%;

    .deni-react-treeview-container.classic {
        //width: 325px;
        color: #212529 !important;

        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            //background: rgba(144, 164, 174, .1);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            //background: rgba(144, 164, 174, .3);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            //background: rgba(144, 164, 174, .6);
        }

        /* changing the font color, font size and line's height */
        .deni-react-treeview-item-container.classic {
            &.selected {
                outline-color: transparent !important;
                background: none !important;
                //font-weight: 600;
            }

            //height: 28px;
            // background: none !important;
            // border: none !important;
            &:hover {
                background-color: transparent !important;
                border: none !important;

                &.select-row {
                    background-color: transparent !important;
                }

                &:not(.select-row) {
                    background-color: transparent;
                }
            }

            .checkbox {
                width: 16px;
                height: 16px;
                border-radius: 4px;
                stroke-width: 1;
                background-color: #fff !important;
                border-color: #fff !important;

                &.checked {
                    background-color: #1976d2 !important;
                    border-color: #1976d2 !important;
                }

                &.undetermined {
                    background-color: #4782bd !important;
                    border-color: #4782bd !important;
                }

                &.selected {
                    // background-color: #fdbd17 !important;
                    border-color: #1976d2 !important;
                    //border-color: #fff !important;
                }
            }

            .expand-button {
                background-image: url(images/Combined-Shape.svg) !important;
                width: 18px;
                height: 18px;
                fill: #727178;
                margin: 5px;

                &.colapsed {
                    &:before {
                        content: "+";
                        position: relative;
                        white-space: pre;
                        display: inline;
                        top: 0px;
                        width: 200px;
                        text-align: center;
                        font-size: 14px;
                        display: block;
                        color: #727178
                    }

                }

                &.expanded {

                    &:before {
                        content: "-";
                        position: relative;
                        white-space: pre;
                        display: inline;
                        top: 0px;
                        width: 200px;
                        text-align: center;
                        font-size: 14px;
                        display: block;
                        color: #727178
                    }

                }

            }

            /* changing the font color, and padding top and bottom (left is not recommended) */
            .icon-and-text {
                text-decoration: none solid rgb(33, 37, 41) !important;
                //line-height: 32px !important;
                font-size: 14px !important;

                // color: #00695C;
                // padding-top: 13px;
                // padding-bottom: 13px;
                // display: flex;
                // flex-direction: row;
                // align-items: center;
                /* changing the selected item properties */
                &.selected {
                    background-color: transparent !important;
                    border: none !important;
                    // color: #004D40 !important;
                    // border-color: red;
                    // background: linear-gradient(to bottom, #E0F2F1 0%, #80CBC4 100%) !important;
                }

                /* changing the icons of the folders expanded */
                // .icon {
                //     width: 24px !important;
                //     height: 24px !important;
                //     /* changing the icons of the folders colapsed */
                //     //&.colapsed {
                //     background-image: url(images/CombinedShape.png) !important;
                //     background-size: 24px 24px;

                //     //}
                //     /* changing the icons of the folders expanded */
                //     &.expanded {
                //         background-image: url(images/CombinedShape.png) !important;                        
                //     }

                //     /* changing the icons of the items */
                //     &.isleaf {
                //         background-image: url(images/CombinedShape.png) !important;                        
                //     }

                // }
            }
        }

    }

}