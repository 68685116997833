.notescard {
  font-size: 14px;
  //border-bottom: 1px solid #cecece;
  display: flex;
  flex-direction: column;

  padding: 10px;
  // border-radius: 10px;
  // stroke-width: 1;
  // background-image: url('../../../../assets/images/Rectangle.svg');
  background-repeat: round;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;

  .chp-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 7px;    
    color: #212529;
    text-decoration: none solid rgb(33, 37, 41);
    line-height: 32px;
  }

  p {
    line-height: 1.5em;
    margin-bottom: 0.5em;
  }

  .text {
    width: auto;
    padding: 0px 0 10px 0;
    color: #000;
  }

  ul {
    padding-left: 0px;
    list-style: none;
  }

  .note-content {
    display: flex;
    justify-content: space-between;

    .review {
      color: #a1a1a1;
      font-style: italic;
      text-align: right;
    }
  }

  .highlight-content {
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .delete-note,
  .delete-highlight {
    cursor: pointer;
    width: 20px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.my-video {
  font-size: 14px;
  border-bottom: 1px solid #cecece;
  display: inline-block;
  padding: 10px 10px;
  // margin-bottom: 10px;
  background-color: #ffffff;
  .my-thumb-video {
    cursor: pointer;
    width: 90px;
    margin-right: 10px;

    img {
      width: 90px;
    }
  }

  .text {
    justify-content: center;

    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      cursor: pointer;
    }

    p {
      line-height: 1.3em;
      margin-bottom: 0.5em;
      cursor: default;
    }

    .date {
      cursor: default;
      color: #a1a1a1;
      font-style: italic;
    }

    .video-content {
      display: flex;
      justify-content: space-between;
    }

    .delete-bookmark {
      cursor: pointer;
      width: 20px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}