.tourcontent_container {
    border-bottom: 1px solid #ccc;
    margin: -10px;
    padding-bottom: 10px;
}

.tourLogo {
    width: 50px;
}

.tourTitle {
    text-align: left;
    font-weight: bold;
}

.tourcontentDiv {
    padding: 0px;
    width: 100%;
    // padding-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.tourcontent {
    padding: 0px;
    width: 100%;
    text-align: left;
    float: left;
}

.tourcontentImage {
    padding-left: 0px !important;
    margin-bottom: 15px;
    width: 100%;
}

.tourcontentHeaderSpan {
    font-weight: bold;
    font-size: 15px;
    float: left;
}

.tourcontentDetails {
    width: 100%;
    margin-top: 10px;
    float: left;
}

.react-joyride__tooltip {
    padding: 7px !important;
}

button:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}