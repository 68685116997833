.canvas-container {
    /* border-top: 2px solid gray; */
    display: block;
    /* min-height: 620px; */
    width: 100%;
    /* overflow-y: auto; */
    height: 100%;
    position: absolute;
    /* background-image: url(../../assets/images/background.png); */

}

.canvas-container-parent {
    /* height: 480px; */
    overflow-y: auto !important;
    display: flex;
    width: 100%;
    position: absolute;
    background-image: url(../../assets/images/background.png);
    border-right: 1px solid grey !important;
}

#canvas_container_parent::-webkit-scrollbar {
    width: 1em;
    height: 2em
}

#canvas_container_parent::-webkit-scrollbar-button {
    background: #ccc
}

#canvas_container_parent::-webkit-scrollbar-track-piece {
    background: #888
}

#canvas_container_parent::-webkit-scrollbar-thumb {
    background: #eee
}

.canvas-containerNone {
    /* border-top: 2px solid gray; */
    display: none;

}

.canvas {
    padding: 0;
    margin: auto;
    display: block;
    touch-action: none;
    /* opacity: '0.3'; */
    /* height: calc(100vh - 220px) !important; */
}

.latex-renderer-container {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #FFFFFF;
    height: 50px;
    font-size: 12px;
}


.canvas-buttonh {
    display: flex;
    width: 300px;
    margin-bottom: 5px;
    margin-top: 2px;
    margin-right: 1%;
    z-index: 10;
    margin-left: 40px;
}

.canvas-buttonhmobile {
    display: flex;
    /* width: 370px; */
    /* width:180px; */
    margin-bottom: 5px;
    margin-top: 2px;
    margin-right: 2%;
    margin-left: 5px;
}

.canvas-buttons-containerh {
    height: 40px;
    padding: 5px 15px;
    width: 100%;
    background: #ECF1FE;
    border-radius: 25px;
    display: flex;

    justify-content: space-between;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
}

.canvas-buttons-containerh .MuiButtonBase-root {
    padding: 0 0 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.canvas-buttons-containerh .MuiButtonBase-root:hover {
    background-color: rgba(0, 0, 0, 0);
}

.canvas-buttons-containerh .MuiButtonBase-root:disabled {
    opacity: 0.5;
}

.canvas-buttons-containerh {
    height: 40px;
    padding: 5px 15px;
    width: 100%;
    background: #ECF1FE;
    border-radius: 25px;
    display: flex;

    justify-content: space-between;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
}

.canvas-buttons-containerh .MuiButtonBase-root {
    padding: 0 0 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.canvas-buttons-containerh .MuiButtonBase-root:hover {
    background-color: rgba(0, 0, 0, 0);
}

.canvas-buttons-containerh .MuiButtonBase-root:disabled {
    opacity: 0.5;
}

.canvas-buttons {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    left: -68px;
    top: 10px;
    z-index: 11;
}

.canvas-buttons-container {
    padding: 5px 3px;
    width: 90px;
    background: #ECF1FE;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
}

.canvas-buttons-container .MuiButtonBase-root {
    padding: 0 0 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
}

.canvas-buttons-container .MuiButtonBase-root:hover {
    background-color: rgba(0, 0, 0, 0);
}

.canvas-buttons-container .MuiButtonBase-root:disabled {
    opacity: 0.5;
}

.imageUploadInput {
    visibility: hidden;
}

.custom-file-upload {
    /* border: 0px solid #ccc;
    display: inline-block;
    padding-top: 12px;
    cursor: pointer;
    font-size: 27px;
    color: #aeb3bd;
    padding-left: 15px; */
    margin: 0;
    padding-left: calc(50% - 5px);
}

body {
    user-select: none;
}

.canvas-buttons {
    font-size: 30px;
    line-height: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    /* color: #4252AF;
    &:disabled{
        background-color: transparent;
        color: rgba(0, 0, 0, 0.26);
    } */
}

.boardBtnsupper {
    width: 20px;
    height: 20px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;


}

.boardBtnsuppermob {
    width: 25px;
    height: 25px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin-top: -8px;
    /* margin: 5px 0 2px 0; */
}

.boardBtnsuppermob:hover {
    background-color: #C3D1F4;
}

.boardBtnsuppermob img {
    filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
    /*#4252AF*/
}

.boardBtnsuppermob.selected {
    background-color: #4252AF;
}

.boardBtnsuppermob.selected img {
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
}



.boardBtnsupper:hover,
.boardBtnsupperUsage,
.boardBtnsupperErase,
.boardBtnExpand,
.boardBtnsupperUsage:hover,
.boardBtnExpand:hover,
.boardBtnsupperSaveMyWork:hover {
    background-color: #C3D1F4;
}


.boardBtnsupper img,
.boardBtnExpand img,
.boardBtnsupperSaveMyWork img {
    filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
    /*#4252AF*/
}

.boardBtnsupper.selected,
.boardBtnsupperUsage.selected {
    background-color: #4252AF;
}

.boardBtnsupperUsage {
    width: 36px;
    height: 36px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;
}

.boardBtnsupperErase {
    width: 20px;
    height: 20px;
    background-color: #ECF1FE;
    color: #4252AF;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;
}

.boardBtnsupperSaveMyWork {
    width: 20px;
    height: 20px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;
}

.boardBtnExpand {
    width: 20px;
    height: 20px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;
    padding-top: 6px;
}

.boardBtnsupper.selected img {
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
}

.boardBtnsuppermobeq {
    width: 25px;
    height: 25px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin-top: -3px
        /* margin: 5px 0 2px 0; */


}

.boardBtnsuppermobeq:hover {
    background-color: #C3D1F4;
}

.boardBtnsuppermobeq img {
    filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
    /*#4252AF*/
}

.boardBtnsuppermobeq.selected {
    background-color: #4252AF;
}

.boardBtnsuppermobeq.selected img {
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
}

.boardBtns {
    width: 36px;
    height: 36px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;
}

.boardBtns:hover {
    background-color: #C3D1F4;
}

.boardBtns img {
    filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
    /*#4252AF*/
}

.boardBtns.selected {
    background-color: #4252AF;
}

.boardBtns.selected img {
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
}


.boardBtnswithoutFilters {
    width: 36px;
    height: 36px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin: 5px 0 2px 0;
}

.boardBtnswithoutFilters:hover {
    background-color: #C3D1F4;
}


.boardBtnswithoutFilters.selected {
    background-color: #4252AF;
}

.boardBtnswithoutFilters.selected img {
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
}

.submitWorkBtnContainer {
    padding: 0px 0 0 0 !important;
    height: 48px;
    width: auto;
    max-width: 48px;
    margin-top: 7px !important;
}

.submitWorkBtnContainer:hover {
    max-width: 300px;
    transition: all 0.4s;
}

.submitWorkBtnContainerGrade {
    padding: 0px 0 0 0 !important;
    height: 48px;
    width: auto;
    max-width: 48px;
    margin-top: 7px !important;
}

.submitWorkBtnContainerGrade:hover {
    max-width: 700px;
    transition: all 0.4s;
}

.submitText {
    white-space: nowrap;
    padding-right: 20px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;

}

.submitTextGrade {
    /* white-space: nowrap; */
    padding-right: 15px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    width: auto;
    padding: 10px;
    word-wrap: break-word;
}

.submitWorkBtn {
    z-index: 10;
    background-color: #4252AF;
    border-radius: 24px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: 48px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        left: -15px !important;
    }
}

.submitWorkBtnQuiz {
    z-index: 10;
    background-color: #4252AF;
    border-radius: 14px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: 70px;
    flex-direction: column;
    width: 95px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {}
}

.submitTextQuiz {
    padding-right: 20px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 10px;
    word-wrap: break-word;
}

.calculateGradeBtn {
    z-index: 10;
    background-color: #4252AF;
    border-radius: 14px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: 70px;
    flex-direction: column;
    width: 95px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        left: -10px !important;
    }
}


.submitWorkBtnContainer2 {
    left: -10px;
}

.submitWorkBtnContainer3 {
    left: -14px !important;
}

.submitWorkBtn img {
    /* filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%); #4252AF */
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
    padding: 0 12px;
}

.calculateGradeBtn img {
    /* filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%); #4252AF */
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
    padding: 0 8px;
}

.uploadOptionsContainer {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    height: 300px;
    width: 260px;
    padding: 15px;
    background: #ECF1FE;
    border-radius: 20px;
    left: 50px;
    top: -60px;
    font-size: 16px;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
}

.uploadOptionsContainer.show {
    display: block;
    visibility: visible;
    opacity: 1;
    left: 65px;
    transition: all 0.3s;
}

.uploadOptionsContainer div.closeIcon {
    cursor: pointer;
    right: 5%;
    display: flex;
    position: absolute;
}

.moreOptionsContainer {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    /* height: 200px; */
    width: 50px;
    padding: 5px 2px;
    background: #ECF1FE;
    border-radius: 25px;
    left: 50px;
    /* top: calc(100% - 323px); */
    bottom: -6px;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
}

.moreOptionsContainer.show {
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
    left: 75px;
    transition: all 0.3s;
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    .moreOptionsContainer.show {

        left: 70px !important;
        bottom: 40px !important;
    }

    .uploadOptionsContainer {
        z-index: 99;
        top: -330px !important;
        left: -194px !important;
    }

    .canvas-container-parent-mobile {
        scrollbar-width: thin !important;
        width: 99% !important;
    }

}

@media only screen and (min-width: 1281px) {
    .moreOptionsContainer.show {

        left: 70px !important;
        bottom: 40px !important;
    }

    .uploadOptionsContainer {
        z-index: 99;
        top: -330px !important;
        /* left: -170px !important; */
    }
}

@media only screen and (min-width: 768px) {
    .moreOptionsContainer.show {

        left: 75px !important;
        bottom: 40px !important;
    }

    .uploadOptionsContainer {
        z-index: 99;
        top: -50px !important;
        /* left: -170px !important; */
    }
}

@media only screen and (min-width: 992px) {
    .moreOptionsContainer.show {

        left: 70px !important;
        bottom: 40px !important;
    }

    .uploadOptionsContainer {
        z-index: 99;
        top: -50px !important;
        /* left: -170px !important; */
    }
}


.browseImageBtn {
    border: 1px dotted #4252AF;
    border-radius: 20px;
    padding: 8px 25px;
    font-weight: 500;
    color: #4252AF;
    cursor: pointer;
    margin: 0;
}

.orTxt {
    color: #000000;
    margin: 0;
    line-height: 1;
}

.scanTxt {
    margin: 0;
    color: #4252AF;
    font-weight: 500;
    line-height: 1;
    padding-top: 16px;
    padding-bottom: 16px;
}

.qrCodeImg {
    height: 140px;
    width: 140px;
    margin: 0 auto;
}

.toolDivider {
    height: 3px;
    width: 30px;
    background-color: #9AABD7;
    border-radius: 3px;
    margin: 10px auto 4px auto
}

.toolLabel {
    margin: 0;
    font-size: 9px;
    color: #000000;
    text-align: center;
    line-height: 1;
}

@media screen and (max-width:1024px) and (max-height:768px) {
    /* .canvas {
        height: calc(100vh - 110px) !important;
    } */
}

.blink {
    animation: blink-animation 1s infinite !important;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.boardBtnsreview {
    width: 20px;
    height: 20px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;

}



.boardBtnsreview:hover {
    background-color: #C3D1F4;
}

.boardBtnsreview img {
    filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
    /*#4252AF*/
}

.boardBtnsreview.selected {
    background-color: #4252AF;
}

.boardBtnsreview.selected img {
    filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
    /*#FFFFFF*/
}

.boardBtnsequations {
    width: 25px;
    height: 25px;
    /* background-color: #ECF1FE; */
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin-top: -5px;
    margin-right: 4px;

    /* margin: 5px 0 2px 0; */
}

.eraser-cursor {
    cursor: url(../../assets/images/eraser-icon.png), auto;
}

.submitWorkBtnQuizDisabledDiv {
    background-color: lightgray !important;
    z-index: 10;
    border-radius: 14px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: 70px;
    flex-direction: column;
    width: 95px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {}
}