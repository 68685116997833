.curriculum-content-img {
  margin-top: 40px;
  margin-bottom: 30px;
}
.curriculum-title {
  margin-top: 40px;
  border-bottom: 1px solid #bbbbbb;
  color: #303030;
}
.toggle-discription {
  position: absolute;
  right: 10px;
  margin-top: 2px;
  cursor: pointer;
}
.toggle-discription .arrow {
  transform: rotateZ(-0deg);
  padding: 10px;
}
.curriculum-title .first {
  margin: 0;
}
.content h1 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 7px;
}
.content h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 22px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.content h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}
.content h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.component-title {
  color: #000eff;
  font-size: 17px;
  margin: 15px 0;
}
.video-group {
  margin-bottom: 15px;
  width: 90%;
}
.preview-video {
  margin-bottom: 15px;
}
.preview-video .rating img {
  padding: 10px;
}
.video-img {
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.video-link-modal {
  img {
    height: 338px;
  }
}
.video-text {
  display: flex;
  flex-direction: column;
  color: #303030;
}
.video-text .second {
  margin-bottom: 15px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.link-icon {
  position: absolute;
  right: 10%;
  margin-top: 6px;
}
.rating-button-box .clearfix {
  width: unset;
}
.video-component {
  border-bottom: 1px solid #bbbbbb;
}
.video-discription {
  padding-bottom: 20px;
  color: #303030;
  height: auto;
  text-align: justify;
}
.setheight {
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.setheight p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 20px;
}
.rotateArrow {
  transform: rotateZ(-180deg) !important;
}
.video-discription p {
  line-height: 18px;
}
.c-red {
  color: red;
  text-decoration: underline;
}
.c-green {
  color: green;
  text-decoration: underline;
}
.c-blue {
  color: blue;
  text-decoration: underline;
}
.c-orange {
  color: orange;
  text-decoration: underline;
}
.c-parpal {
  color: #5928be;
  text-decoration: underline;
}
.rating {
  float: left;
  margin-top: 25px;
}
.main-video-rating,
.video-rating {
  float: left;
}
.rating img {
  padding: 8px;
}

.rating-hover {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.rating-hover .rating-modal-title {
  font-size: 16px;
  color: #303030;
  text-align: left;
  font-weight: 600;
  margin-bottom: 5px;
}
.rating-hover .write-review {
  padding: 4px 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.rating-hover .slidecontainer {
  width: 100px;
}
.rating-hover .write-review-slider {
  padding: 4px 0 2px 0;
}
.rating-hover .ratingBox {
  // visibility: hidden;
  display: none;
  width: 245px;
  padding: 10px 15px;
  background-color: #f5f5f5;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  cursor: default;
  z-index: 100;
  top: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
}
.rating-hover .ratingBox::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #f5f5f5 transparent;
}
.rating-hover .ratingBox::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.3) transparent;
}
.rating-hover:hover .ratingBox {
  display: block;
}

.write-review {
  border-top: 1px solid #ebebeb;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}
.write-review h2 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  flex: 1;
}
.write-review .rating {
  margin: 0;
}
.write-review-slider {
  border-top: 1px solid #ebebeb;
  padding: 12px 0 7px 0;
  display: flex;
  justify-content: space-between;
}
.write-review-slider h2 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  flex: 1;
}
.ratingBox .slidecontainer {
  width: 160px !important;
  flex: 1;
  padding-left: 10px;
}
.slidecontainer {
  font-size: 12px;
  color: #a1a1a1;
}
.slidecontainer label {
  float: left;
  margin-top: 4px;
}
.slidecontainer .fast {
  float: right;
}
.rating-hover .slidecontainer {
  width: 100px;
}

.rating-hover h2 {
  font-size: 14px !important;
  color: #303030 !important;
  text-align: left !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.yellow-btn {
  // height: 30px;
  line-height: 16px;
  font-size: 14px;
  // width: 125px;
  background-color: #4252AF;
  border-radius: 0;
  color: #fff;
  border: 0px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #707070;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #fdbd17;
  cursor: pointer;
  border-radius: 50%;
}
.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #fdbd17;
  cursor: pointer;
  border-radius: 50%;
}
.description-title {
  display: inline-block;
}
.description-expand {
  float: right;
  padding: 8px;
  cursor: pointer;
  pointer-events: auto;
}

.rating-button-box {
  display: flex;
  justify-content: flex-end;
}
// .rating-button-box button,
// .rating-button-box button:hover {
//     background-color: #fdbd17;
// }
