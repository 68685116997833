.recognition {
  width: 33.33333%;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .count {
    width: 50px;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    background-color: #f2f2f2;
    text-align: center;
    font-size: 24px;
    color: #345ddf;
    font-weight: 600;
    padding: 8px 11px;
    margin: 0 auto 10px auto;
  }

  .text {
    font-size: 14px;
    // font-weight: 500;
    // color: #000;
    text-align: center;
    flex: 1;
    width: unset;
  }

  .recognition-reviewer {
    flex: 1;

    .top-reviewer {
      width: 67px;
      height: 24px;
      padding: 3px 9px 0 0;
      margin: 20px auto;
      font-size: 13px;
      font-weight: 600;
      color: #000;
      background-color: #ebf1ff;
      position: relative;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 10px solid #fff;
      }
    }
  }
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;

  .border-bottom {
    border-bottom: 1px solid #e9ecef;

    .popup_count {
      font-size: 26px;
      padding-top: 4px;
      width: 50px;
      height: 50px;
      border: 1px solid #d9d9d9;
      border-radius: 50%;
      background-color: #f2f2f2;
      text-align: center;
      color: #345ddf;
      font-weight: 500;
      margin: 0 auto 10px auto;
    }

    h4 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 22px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  p {
    line-height: 1.5em;
    margin-bottom: 0.5em;
    max-width: 580px;
  }

  .curiosities-title {
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .ribbon-container {
    width: 100%;
  }

  .topReviewer {
    width: 200px;
    height: 50px;
    background-color: #bfd4ff;
    position: relative;
    text-align: center;
    padding: 18px;
    margin: 30px auto;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
  }

  .topReviewer:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 20px solid #fff;
  }

  .topReviewer-small {
    width: 200px;
    height: 40px;
    background-color: #bfd4ff;
    position: relative;
    text-align: center;
    padding: 18px;
    margin: 18px auto;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
  }

  .topReviewer-small:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #fff;
  }
}