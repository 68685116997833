.chsymbols_container {

    .bg-white-with-shadow1 {
        background-color: #fff;
        border: 1px solid #e0e4e8;
        border-radius: 16px;
        -webkit-box-shadow: 0px 8px 24px 0px #959da533;
        -moz-box-shadow: 0px 8px 24px 0px #959da533;
        box-shadow: 0px 8px 24px 0px #959da533;
        padding: 10px 24px !important;
       
    }
}