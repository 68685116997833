.probStatementContainer {
    height: calc(100vh - 40px);
}

.probStatement {
    padding-bottom: 20px;

    .MuiAccordion-root {
        -webkit-box-shadow: 3px 4px 36px 0px rgba(185, 183, 175, 0.28);
        -moz-box-shadow: 3px 4px 36px 0px rgba(185, 183, 175, 0.28);
        box-shadow: 3px 4px 36px 0px rgba(185, 183, 175, 0.28);
    }

    .MuiAccordionSummary-root {
        padding-left: 5px;
        flex-direction: row-reverse;
        align-items: flex-start;

        &.Mui-expanded {
            min-height: 48px;
        }
    }

    .MuiAccordionSummary-content {

        &.Mui-expanded {
            margin: 12px 0 5px 0;
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px 10px 10px 40px;
    }

    .MuiAccordionSummary-expandIconWrapper {
        // padding-top: 10px;
        margin-top: 10px;
        margin-right: 8px;
        border-radius: 100%;
        background: #576374;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);

        svg {
            fill: #ffffff;
        }

        &.Mui-expanded {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }
}

.screentitle {
    color: #4252AF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
}

.cursorPointer {
    cursor: pointer;
}

.footerBtns {
    width: 36px;
    height: 36px;
    background-color: #ECF1FE;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;

    &:hover {
        background-color: #C3D1F4;
    }

    img {
        filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
        /*#4252AF*/
    }

    &.selected {
        background-color: #4252AF;

        img {
            filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
            /*#FFFFFF*/
        }
    }
}

.footerBtnshint {

    position: relative;

}

.equationBtn {
    height: 44px;
    width: 44px;
    background-color: #4252AF;

    img {
        filter: invert(91%) sepia(99%) saturate(33%) hue-rotate(252deg) brightness(107%) contrast(100%);
        /*#FFFFFF*/
    }
}

.solFooterBtns {
    width: 97.7%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    z-index: 10;
    -webkit-box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.05);

    .MuiIconButton-root {
        display: flex;
        flex-direction: column;
        padding: 0 0 5px 0;
        z-index: 1;

        span {
            font-size: 10px;
            text-transform: uppercase;
            line-height: 1.1;
        }
    }

    // &::after {
    //     content: "";
    //     height: 90px;
    //     width: 90px;
    //     border-radius: 100%;
    //     background: white;
    //     position: absolute;
    //     top: -15px;
    //     left: calc(50% - 49px);
    //     z-index: 0;
    // }
}

.errorLine {
    display: inline-flex;
    border: 2px solid #f3ccca;
    background: #fee9e8;
    padding: 5px 5px 7px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
}

.noErrorLine {
    display: inline-flex;
    border: 2px solid #C9F1C9;
    background: #EBFBEB;
    padding: 5px 5px 7px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.wrs_handWrapper {
    display: none;
}

.wrs_formulaDisplayWrapper {
    margin-right: 0 !important;
}

.submitmobile {
    position: absolute;
    z-index: 999;
    left: 1%;
    // top: 12%;
}
.questiondescription {
    padding-left: 10px;
    font-size: 18px;
    color: rgb(48, 48, 48);
    font-family: Roboto;
    font-weight: 600;
    line-height: 1.4;
  }

@media only screen and (max-width: 600px) {
    .submitmobile {
        position: absolute;
        z-index: 999;
        left: 1% // top: 12%;
    }

    .mobileviewSpec {
        word-wrap: break-word;
    }
    .modal-size-medium {
        width:95% !important;
    }
    .modal-width
    {
        min-width: auto;
    }

    .solFooterBtns {
        width: 99% !important;

        // &::after {
        //     content: "";
        //     height: 90px;
        //     width: 90px;
        //     border-radius: 100%;
        //     background: transparent;
        //     position: absolute;
        //     top: -15px;
        //     left: calc(50% - 49px);
        //     z-index: 0;
        // }
    }
}

.footer {
    position: fixed;
    bottom: 0;
    // width: 97% !important;
}