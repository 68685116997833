.curriculum-right-fix {
    /*float: right; */
    border-radius: 5px;
    background-color: #ebf1ff;
    border: 1px solid #cbd3e5;
    width: 100%;
    padding: 10px;
    height: calc(100vh - 175px);
    overflow: auto;
     -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.vidWindowBtn{
    background: transparent;
    color: #666;
    padding: 0 3px;
}
.tutor-profile {
    color: #303030;
    h2{
        font-size: 22px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .tutor-link {
        cursor: pointer;
        margin-left: 8px;
        color: blue;
    }
}
.tutor-profile .loves {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 5px;
}
.tutor-profile .degree {
    font-size: 14px;
}
.open-new-tab-icon,
.open-new-tab-icon:hover {
    float: right;
    cursor: pointer;
    margin: 8px 0px;
}
.clearfix iframe {
    height: 200px;
    width: 100%;
    border: 0px;
}
.panel-box {
    background-color: #fff;
    margin-bottom: 20px;
    border: 1px solid #b7b7b7;
    color: #303030;

    .chat__content {
        max-height: 300px;
        background-color: #fafafa;
        display: flex;
        flex-direction: column;
        flex: 1 1;

        .chat__messages {
            border-left: solid 1px rgba(0, 0, 0, 0.03);
            border-right: solid 1px rgba(0, 0, 0, 0.03);
            padding: 0 12px;
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1 1;
            min-height: 200px;

            .message__timestamp {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.83;
                text-align: center;
                color: #b3b3b3;
                text-transform: uppercase;
                padding-top: 4px;
                display: block;
            }

            .message {
                display: flex;
                margin: 5px 0 0 0;
                min-height: 30px;
                height: auto;
                text-align: left;

                .message__avatar {
                    width: 22px;
                    min-width: 22px;
                    height: 22px;
                    max-height: 22px;
                    display: block;
                    margin-right: 5px;
                }

                .message__box {
                    display: flex;
                    flex: 1 1 100%;
                    flex-direction: column;

                    .message__content {
                        -ms-flex-direction: row;
                        flex-direction: row;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        display: -ms-flexbox;
                        display: flex;

                        &:last-of-type {
                            margin-bottom: 20px;
                        }
                        &:first-of-type {
                            margin-bottom: 0;
                        }
                        
                        p {
                            background: #333333;
                            display: inline-block;
                            margin: 0;
                            width: auto;
                            padding: 8px 10px 8px 10px;
                            word-wrap: break-word;
                            white-space: pre-wrap;
                            word-break: break-all;
                            border-radius: 8px;
                            color: #fff;
                        }
                    }
                }

                .message__box.self {
                    .message__content {
                        -ms-flex-direction: row-reverse;
                        flex-direction: row-reverse;

                        p {
                            background: #56c376;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .chat__footer {
        border: solid 1px #e3e3e3;
        border-top: none;
        background-color: #f7f7f7;
        max-height: 100px;
        overflow: hidden;
        position: relative;

        .chat__input {
            position: relative;
            margin: 0;
            outline: none;
            white-space: pre-wrap;
            word-wrap: break-word;
            width: 100%;
            overflow-y: auto;
            font-size: 16px;
            font-family: "Lato", Arial, sans-serif;
            border: none;
            cursor: text;
            background-color: #f7f7f7;
            resize: none;
            padding: 3px 10px;
            height: inherit;
            line-height: 1.5;
            border-top: 8px solid #f7f7f7;
            border-bottom: 8px solid #f7f7f7;
        }

        textarea {
            overflow: auto;
        }
    }
}
.panel-box label {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #b7b7b7;
    padding: 12px 10px;
    line-height: 1.2em;
    width: 100%;
}
.problem-img {
    width: 60%;
}
.responsiv-img {
    width: 100%;
}
