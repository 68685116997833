.feedbackBoxcss {
    float: left;
    // margin-left: 40px !important;
    //margin-bottom: 50px !important;
    // margin-top: 40px !important;
}

.feedbackBoxSubmit {
    // margin-left: 40px !important;
    //margin-bottom: 50px !important;
}

.whiteBGFeedback {
    background-color: #ffffff;
    // padding: 20px;
    border-radius: 20px;
    width: 100% !important;
}

.btnStart {
    width: 180px;
    border-radius: 48px;
    background: #003392;
    box-shadow: 0px 1px 8px 3px rgba(163, 192, 242, 0.5);
    color: #fff;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-family: proxima-nova, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 22px;
    border: 0;

    &:hover {
        text-decoration: none;
        color: #000;
        background: #fff;
    }
}

.arrow {
    font-family: sans-serif;
    color: #1976D2;
    font-size: xx-large !important;
    cursor: pointer;
}

.blink {
    font-family: sans-serif;
    color: #1976D2;
    animation: blinker 1.8s linear infinite;
    cursor: pointer;
    font-size: xxx-large !important
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#slide {
    transition: all 1s;
}

#slide.hide {
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
}



// #fade {
//     transition: all 1s;
// }

// #fade.hide {
//     opacity: 0;
//     visibility: hidden;
//     height: 0;
//     padding: 0;
//     margin: 0;
//     /* optional */
// }

// #collapse {
//     transition: all 1s;
// }

// #collapse.hide {
//     transform: scaleY(0);
//     visibility: hidden;
//     height: 0;
//     padding: 0;
//     margin: 0;
//     /* optional */
// }

// #roll {
//     transition: all 1s;
// }

// #roll.hide {
//     transform: translateX(-100%) rotateZ(180deg);
//     opacity: 0;
//     visibility: hidden;
//     height: 0;
//     padding: 0;
//     margin: 0;
// }

.whiteBGFeedback .MuiGrid-item {
    padding-top: 0px !important;
}

.css-a7nzja {
    margin-top: 0px;
}