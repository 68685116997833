.cke_inner {
    display: none !important;
}

.divCKEditor {
    width: 100%;
    display: 'inline-block';
    text-align: left;
}

.ck-tooltip__text {
    margin-left: 50% !important;
}

.ck-button__icon {
  color: #4252AF !important;
  background: transparent !important;
}