.friends-thumb {
  height: 55px;
  float: left;
  margin-right: 10px;
  width: 90px;
  overflow: hidden;
  margin-bottom: 15px;

  img {
    height: 100%;
    width: auto;
    margin-right: 15px;
  }
}

.text {
  float: left;
  width: calc(100% - 100px);

  h3 {
    font-weight: 400;
    font-size: 16px;
    max-height: 54px;
    overflow: hidden;
    line-height: 18px;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }
}

.video-card {
  cursor: pointer;
}

.detail-card,
.detail-card:hover {
  text-decoration: none;
  color: inherit !important;
}

.video-scroll {
  height: 206px;
  overflow-y: scroll;
  width: 263px;
}

.img-container {
  border: 1px solid #e5e5e5;

  img {
    margin-right: 15px;
    max-width: 100%;
  }
}

.width100 {
  width: 100% !important;
}
