.video-thumbnail {
    // padding: 10px;
    // border-radius: 10px;
    // stroke-width: 1;
    // background-image: url(/static/media/Rectangle.eb1b1d94.svg);
    // background-repeat: round;
    // margin-top: 15px;
    // background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    margin-top: 15px;

    .video-title {
        svg {
            cursor: pointer;
        }
    }

    .content-holder {
        &.hide-content {
            display: none;
        }

    }
    .imageContainer {
        cursor: pointer;
    }
    label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 18px;
        padding-top: 15px;
        font-weight: 500;
        line-height: 1.3em;
        color: #303030;
    }

    span {
        font-weight: 400;
    }

    img {
        cursor: pointer;
        border-radius: 10px;
    }

    a {
        display: block;
        cursor: pointer;
    }
}