.tutorprofile {
  .tutorprofile-left {
    float: left;
    width: 100px;
    overflow: hidden;
    min-height: 84vh;
    padding: 30px 20px;
    color: #303030;

    .clear-filter {
      cursor: pointer;
      text-align: right;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .tutorprofile-right {
    margin: auto;   
    padding: 10px 20px 10px 20px;
    color: #303030;
    // float: left;
    // width: calc(100% - 300px);
    // padding: 0px 20px 0px 20px;
    // color: #303030;
  }
}
.cards {
  transition: all 200ms linear;
  color: black;
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    text-decoration: none;
  }
}
.css-19s31ak {
  display: none !important;
}
.rs__cell:not(.rs__event__item) {
  box-sizing: revert;
}
.rs__event__item {
  width: 100% !important;
}
.MuiButton-root.cancel {
  color:#fff !important;
}
// .rs__header {
//   height:50px !important;  
// }
.rs__cell .rs__hover__op{
  font-size: 1.1rem !important;
}
.MuiTypography-body1{
  font-size: 13px !important;  
  
}
.rs__multi_day {
  padding-top:10px;
}
