.grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-auto-flow: row dense;
}

.item {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  height: 100vh;
}

.large {
  background: rgba(255, 0, 0, 0.25);
  grid-column: auto / span 2;
  grid-row: auto / span 11;
}

.rect {
  border-bottom: 2px solid rgb(4, 122, 122);
  padding-left: 20px;
  padding-bottom: 10px;
}

.equationBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wirisBox {
  left: 10% !important;
}

.wirisMobile {
  width: 96% !important;
  left: 2% !important;
}

.wrs_tickContainer {
  display: none !important;
}

.lables {
  padding-top: 20px;
}

.item-1 {
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

button {
  background: #4252AF;
  color: #ffffff;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px !important;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

label {
  font-size: 16px;
  /* text-transform: capitalize; */
  margin-bottom: 10px;
}

input {
  padding: 8px;
  border-radius: 5px;
  background: #efefef;
  font-size: 14px;
  color: #6e6e6e;
}

textarea {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: #efefef;
}

.Add .rect {
  margin-top: 10px;
  border-bottom: 2px solid rgb(3, 145, 114);
  padding-bottom: 20px;
}

.Add .rect h4 {
  text-align: center;
}

.updatenode__cont label {
  display: block;
}

aside {
  text-align: left !important;
}

aside .codeNodeMain {
  text-align: left !important;
  width: 90%;
  margin-left: 5px;
}

aside .updatenode__bglabel {
  margin-top: 10px;
}

/* .checkboxwrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
} */

.checkboxwrapper input {
  /* margin-top: -7px; */
  margin-right: 5px;
}

.padding0 input[type="checkbox"] {
  margin-left: 5px;
}

.radiowrapper {
  margin-right: 0;
}

.hintItems {
  background-color: #bfd4ff !important;
  margin-bottom: 5px;
  margin-right: 5px;
}

.btn-save {
  margin-top: 20px;
}

.save {
  /* background: #219cde;
  color: white;
  font-size: 16px;
  width: 90%;
  margin-top: 5px;
  border-radius: 5px;
  font-weight: normal;   */
  background: #ffc107;
  color: #000000;
  font-weight: 400;
  margin: 5px;
}

.save:hover {
  background-color: #ffc107;
  color: white;
}

.saveanchor {
  color: #219cde;
  font-size: 12px;
  width: 90%;
  cursor: pointer;
}

.saveanchor:hover {
  color: #fa8764;
}

.back {
  background: #219cde;
  color: white;
  font-size: 18px;
  width: 90%;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  font-weight: normal;
}

.back-button {
  margin-top: 10px;
  margin-bottom: 20px;
}

.side {
  display: block;
}

.dndflow {
  flex-direction: column;
  display: flex;
  height: 100%;

  overflow-y: scroll;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  padding-left: 10px;
  font-size: 12px;
  background: #bfd4ff;
  color: "#00000";
  overflow-y: auto;
  /* max-height: 800px; */
  height: calc(100% - 20px);
}

.dndflow aside .description {
  margin-bottom: 5px;
  font-size: 14px !important;
  /* text-transform: capitalize !important; */
  color: #817f7f !important;
}

.code {
  margin-top: 10px;
  border: 1px solid#448805;
  height: 100px;
  flex-direction: row;
}

.dndflow .dndnode {
  /* height: 20px;
  padding: 15px; */
  width: 100%;
  /* border: 1px solid #1a192b;
  border-radius: 2px; */
  /*   
  display: flex; */
  justify-content: center;
  align-items: center;
  cursor: grab;
  background: transparent;
}


.dndflow .dndnode.output {
  /* width: 145px; */
  color: #007bff;
  background: transparent;
  margin-bottom: 5px;
  padding-top: 8px;
  float: left;
}

.dndflow .MuiCollapse-entered {
  overflow: auto;
}

.leftmargin {
  margin-left: 10px;
}

.saveButtonCotanier {
  margin-top: 10px;
  cursor: pointer;
}

.dragLabelcancel {
  background: transparent;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
}

.dragLabelcancel:hover {
  background-color: #000;
  color: white;
}

.dragLabel {
  background: #ffc107;
  color: black;
  padding: 5px;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 5px;
}

.dragLabel:hover {
  background-color: #ffc107;
  color: white;
}

.dragLabeldisabled {
  padding: 5px;
  border-radius: 5px;
  border-radius: 5px;
  background-color: #cccccc;
  color: #666666;
  font-size: 14px;
  cursor: none;
}

.dragLabeldisabled:hover {
  background-color: #cccccc;
  color: #666666;
}



.dragLabelblue {
  background: #007bff;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 5px;
}

.dragLabelblue:hover {
  background-color: #007bff;
  color: white;
}

/* button {	
  background: #ffc107;	
  color: black !important;	
  padding: 5px;	
  border-radius: 5px;	
  border-radius: 5px;	
}	
button:hover {	
  background-color: #ffc107;	
  color: white;	
} */

.statuslabel {
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  color: rgb(40, 167, 69);
  padding-right: 10px;

}

.statustext {
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;

  padding-right: 10px;

}

.dropDown {
  z-index: 100;
}

.noteText {
  font-size: 9px;
  font-style: italic;
  color: blue;
}

.dndflow .dndnode.circle {
  border-color: #0041d0;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-left: 60px;
  background: #cccccc;
}

.code3 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 200px;
  background: #cccccc;
}

.code4 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 200px;
}

.code5 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 120px;
  background: #cccccc;
}

.code8 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 140px;
  background: #cccccc;
}

.code10 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 220px;
  background: #cccccc;
}

.code10 img {
  width: 50px;
  height: 50px;
  margin-top: 10px;
}

.code12 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 240px;
  background: #cccccc;
}

.code10 p {
  text-align: center;
}

.code11 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 240px;
  background: #cccccc;
}

.code11 img {
  width: 50px;
  height: 50px;
}

.code10 p {
  text-align: center;
}

.code8 img {
  width: 50px;
  height: 50px;
  margin-top: 10px;
}

.code8 textarea {
  margin-top: 5px;
}

.code6 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 170px;
  background: #cccccc;
}

.code6 p {
  text-align: center;
}

.code6 img {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  text-align: center;
}

.code5 p {
  text-align: center;
}

.code4 p {
  text-align: center;
}

.code4 textarea {
  margin-top: 10px;
  text-align: center;
  flex-direction: row;
}

.code4 img {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  text-align: center;
}

.code {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 100px;
  background: #cccccc;
}

.code2 {
  margin-top: 10px;
  border: 1px solid #2ac2ba;
  padding-bottom: 10px;
  height: 150px;
  background: #cccccc;
}

.dndflow .dndnode.square {
  height: 100px;
  width: 100px;
  background: #cccccc;
}


.dndflow .dndnode.custom {
  border-color: #ff0072;
  background: #cccccc;
}

.dndflow .dndnode.only-image {
  margin-top: 10px;
  border-color: #ff0072;
  background: #cccccc;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 90%;
  width: 100%;
}

.DraftEditor-root {
  height: 160px !important;
}

.nodedata {
  position: absolute;
  right: -5px;
  top: 20px;
  z-index: 99;
  height: 98%;
}

.textprocessing .chartContainer {

  height: 100% !important;
  width: 100% !important;

}

/* 
.nodeStyle:hover::after {
  content: attr(title);
  background: #ffffff;
  position: absolute;;
  border: 1px solid #000000;
  padding: 5px;
  border-radius: 5px;
} */



.propertyWindowContainer {
  /* height: 90%; */
  height: calc(100% - 20px);
  display: flex;
}

.image {
  border: 1px solid#0779a7;
  height: 100px;
  flex-direction: row;
  text-align: center;
  margin-top: 10px;
  background: #cccccc;
}

.image img {
  width: 50px;
  height: 50px;
}

.custom {
  margin-top: 10px;
  border: 1px solid#0779a7;
  height: 150px;
  flex-direction: row;
  text-align: center;
  background: #cccccc;
}

.custom img {
  width: 50px;
  height: 50px;
}

.custom-2 {
  margin-top: 10px;
  border: 1px solid#0779a7;
  height: 100px;
  padding: 20px;
  flex-direction: row;
  text-align: center;
  background: #cccccc;
}

.custom-2 img {
  width: 50px;
  height: 50px;
}

.textfield2 {
  margin-top: 10px;
  border: 1px solid#1be7d6;
  height: 50px;
  padding-bottom: 10px;
  flex-direction: row;
  text-align: center;
}

.textfield3 {
  margin-top: 10px;
  border: 1px solid#ff0072;
  height: 80px;
  padding-bottom: 10px;
  flex-direction: row;
  text-align: center;
  background: #cccccc;
}

.textarea2 {
  margin-top: 10px;
  border: 1px solid#ff0072;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  text-align: center;
  background: #cccccc;
}

.textarea2 textarea {
  width: 80px;
}

.textarea3 {
  margin-top: 10px;
  border: 1px solid#2ac2ba;
  height: 120px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  text-align: center;
  background: #cccccc;
}

.textarea3 textarea {
  width: 80px;
}

.textarea {
  resize: none;
  width: 400px !important;
}

.image3 {
  margin-top: 10px;
  border: 1px solid#2ac2ba;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #cccccc;
}

.image3 img {
  width: 50px;
  height: 50px;
}

.image2field1 {
  margin-top: 10px;
  border: 1px solid#2ac2ba;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #cccccc;
  text-align: center;
}

.image2field1 textarea {
  width: 80px;
}

.image2field1 img {
  width: 50px;
  height: 50px;
}

.area2field1 {
  margin-top: 10px;
  border: 1px solid#2ac2ba;
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  background: #cccccc;
  text-align: center;
}

.area2field1 textarea {
  width: 80px;
}

.area2image1 {
  margin-top: 10px;
  border: 1px solid#2ac2ba;
  height: 110px;
  padding-top: 10px;
  background: #cccccc;
  padding-bottom: 10px;
  flex-direction: column;
  text-align: center;
}

.area2image1 img {
  width: 50px;
  height: 50px;
}

.area2image1 textarea {
  width: 80px;
}

.only-image {
  background: #cccccc;
  padding: 20px;
  margin-top: 10px;
  border: 1px solid#ff0072;
  flex-direction: row;
  text-align: center;
}

.only-image img {
  width: 50px;
  height: 50px;
}

.labels {
  font-size: 13px !important;
  color: #000000 !important;
  margin-right: 10px;
  margin-bottom: 0;
}

.unreheader {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
}

.padding0 {
  margin-bottom: 0px !important;
}

input[type=checkbox] {
  vertical-align: middle;
  position: relative;
  /* bottom: 1px; */
}

input[type=radio] {
  vertical-align: middle;
  position: relative;
  /* bottom: 1px; */
}

.modal-body .cke_top {
  /* padding: 0px 8px 0px !important; */
}

.modal-body .cke_reset {
  border: none !important;
  height: 200px !important;
  overflow: hidden !important;
}




.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}


.solutiongraphtest {
  .MuiAccordionSummary-content {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.answerform {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 10px;
  height: 95%;
  margin: 10px;
  padding: 20px;
}

.textareaanswer {
  resize: both !important;
  width: 100% !important;
  height: 100% !important;
  background-color: white !important;
  border: 1px solid #ccc;
}




@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .dndflow aside {
    width: 100%;
    max-width: 100%;
  }
}

.dndflow aside .name-node {
  /* padding-top: 10px; */
  margin-bottom: 5px;
  margin-top: 5px;
}

.dndflow aside .buttondiv {
  /* padding-top: 10px; */
  margin-bottom: 5px;
  margin-top: 0px;
  text-align: end;
}

.addedHintsContainer {
  overflow-y: auto;
  width: 400px;
  padding-right: 5px;
  margin-top: 10px;
}

.list {
  overflow-y: auto;
  height: 100%;
  max-height: 150px;
  overflow-y: auto;
}

.dndflow aside .saveHintNode {
  width: 100%;
  padding-right: 20px;
  text-align: end;
}

.dndflow aside .image-node {
  /* padding-top: 15px; */
}

.dndflow aside .checkboxwrapper {
  pointer-events: auto;
  opacity: 1;
  margin-bottom: 5px;
}

.dndflow aside .checkboxwrapper-disabled {
  pointer-events: none;
  opacity: 0.5;
  margin-bottom: 5px;
}

.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.linkButtongray {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: gray;
  cursor: pointer;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.modal {
  font-size: 12px;
}

.modal>.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal>.content {
  width: 100%;
  padding: 10px 5px;
}

.modal>.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  color: black;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.horizontalRuler {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.none {
  display: none;
}

.dimensions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.toggledfront {
  display: block !important;
}

.toggleContainer1 {
  background-color: transparent;
  padding-top: 5px;
  cursor: pointer;
}

.toggleContainer2 {
  margin-top: 10px;
  cursor: pointer;
  padding: 0px 5px 5px 5px;
  background: #bfd4ff;
}

.infoIconContainer {
  position: absolute;
  top: 0px;
  right: 3px;
  width: 100%;
  text-align: end;
}

.nodeContentContainer {
  width: 100%;
  height: 100%;
  padding-top: 7px;
  font-size: 20px;
}

.contentContainer {
  font-size: 24px;
  max-width: 300px;


}

.react-flow__node:hover {
  z-index: 50 !important;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: 1px solid #0028FF;
}

.contentcontainerresource {
  font-size: 18px;
  max-width: 300px;
  text-align: right;
}

.contentcontainernode {
  font-size: 18px;
  max-width: 300px;
  text-align: right;
  color: "#fdbd17" !important;
}

.recttoggle svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.bottomcontent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin: 0 auto;
  overflow: visible;
  height: 0px !important; */
}

.backdiv {
  padding-top: 0px;
  padding-right: 7px;
  padding-bottom: 15px;
  padding-left: 0px;
  white-space: normal;
  word-break: break-word;
  display: flex;
  align-items: center;
}

.richtext {
  height: auto;
  overflow: visible;
  margin: 0 auto;
  display: inline-block;
}

.primarynode {
  padding-left: 60px;
}

/* scrollbar */
/* ::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
} */

/* Custom node */
.react-flow .react-flow__handle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #784be8;
}

.react-flow .react-flow__handle-top {
  top: -5px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -5px;
}

/* .legendContainer {
  display: flex;
  align-items: center;
  margin-left: 100px;
  margin-top: 10px;
}

.legendItemContainer {
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;

} */
.legendContainer {
  padding-top: 5px;
}

.correct {
  background-color: #bfd4ff;
}

.incorrect {
  background-color: #ff0000;
}

.optional {
  background-color: #cccccc;
}

.legendText {
  font-size: 12px;
}

.hintsRenderItem {
  width: calc(100% - 40px);
  float: left;
}

.resourceDisplay {
  font-weight: 400;
}

.hintsUrlDisplay {
  color: blue;
  font-weight: 400;
}

.deleteHintItem {
  width: 10px;
  margin-left: 10px;
  float: left;
  cursor: pointer;
}

.dropDownSelect {
  z-index: 101 !important;
}

@media (max-width: 1366px) {

  /* .dndflow .dndnode.output {
    width: 145px;
  } */
  .backdiv {
    width: 20%
  }

  .bottomcontent {
    margin-bottom: 20px !important;
  }

  .middlediv {
    width: 40%;
  }

  .dragLabeldisabled {
    font-size: 12px;
  }

  .dragLabel {
    font-size: 12px;
  }
}

.ag-theme-alpine .ag-ltr .ag-cell {
  text-wrap: wrap;
}

.ag-theme-alpine .ag-cell {
  line-height: auto !important;
}

.list-group-itemGrid {
  background-color: transparent !important;
}

.ck-editor__editable_inline {
  min-height: 150px;
  max-height: 155px;
  overflow-y: auto;
}

.ck-editor {
  overflow-x: hidden;
  width: 99% !important;
}