.video-popup-modal {
    width: 70vw !important;
    max-width: 70vw !important;

    .popup-modal-body {
        video {
            width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}
