.modal-width {
  min-width: max-content;

  iframe {
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    min-width: auto !important;
  }
}

.draggable-modal {
  position: absolute !important;
  top: 18vh;
  left: 8vw;
  margin: unset !important;
  cursor: move;
}

@media only screen and (max-width: 600px) {
  .draggable-modal {
    left: 2vw;
  }
}

.modal-size- {
  &small {
    max-width: none !important;
    width: 25% !important;

    @media only screen and (max-width: 600px) {
      width: 96% !important;
      min-width: auto !important;


    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 80% !important;
    }
  }

  &medium {
    max-width: none !important;
    width: 50% !important;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 80% !important;
    }
  }

  &large {
    max-width: none !important;
    width: 75% !important;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 80% !important;
    }
  }
}

.modal-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: 600px) {
    white-space: inherit !important;


  }
}

.resize-container {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 2rem;
  transform: scale(0.7);

  span {
    border: 2px solid;
    margin-right: 5px;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    opacity: 0.6;

    &.resizer-small {
      width: 20px;
      height: 30px;
    }

    &.resizer-medium {
      width: 40px;
      height: 20px;
    }

    &.resizer-large {
      width: 60px;
      height: 30px;
    }
  }
}

.cus-modal {
  button {
    outline: none !important;
  }

  .modal-footer {
    justify-content: space-evenly;
  }

  .modal-footer-button {
    // height: 30px;
    line-height: 16px;
    font-size: 14px;
    width: 125px;
    background-color: #4252AF;
    border-radius: 0;
    color: #fff;
    font-weight: 500;

    &:hover {
      color: #fff;
    }
  }
}

.drag-modal {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 500px;
}

.custommodal {
  position: fixed !important;
  top: auto !important;
  bottom: 2% !important;
  left: 5% !important;
  z-index: 9999 !important;
  display: none;
  width: auto !important;
  height: auto !important;
  overflow: hidden;
  outline: 0;
}

.modalheader {
  padding: 0.50rem 0.50rem !important;
}

.modalfeedbacktitle {
  font-size: 1rem !important;
}