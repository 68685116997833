
.video-YT {
  width:100%; 
  position: relative;
  margin: 0 auto;

  button {
    border: none;
    background-color: #fdbd17;
    font-size: 14px;
    color: #fff;
    padding: 10px 9px;

    &:hover,
    &:active {
      color: white;
    }
  }

  .questionsContiner{
    a{
      color: rgb(124, 124, 255) !important;

      &:hover{
        text-decoration: underline !important;
      }
    }
  }

  
}




.circular-loading{
  color: #fdbd17;
}

.checkpoint-timestamp{
  opacity: 0.7;
  margin-bottom: 5px
}
