@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.border-grey-r {
  //border-right: 1px solid #707070;
}

.title {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #cecece;

  img {
    width: auto;
    height: 22px;
    margin-right: 7px;
  }
}

@include media-breakpoint-down(md){
  .border-grey-r {
    border-right: none
  }
}