.drop-down-list {
  margin-bottom: 10px;

  select {
    width: 100%;
    height: 28px;
    font-size: 14px;
  }
}

.tree-view-notes {
  width: 100%;
  // height: 100%;

  .clear-filter {
    cursor: pointer;
    text-align: right;

    &:hover {
      text-decoration: underline;
    }
  }

  .deni-react-treeview-container {
    background-color: transparent !important;
    font-size: 14px;
    width: 100%;
    height: 100%;
    border: none;
    overflow-x: unset;
    overflow-y: unset;

    /* changing the font color, font size and line's height */
    .deni-react-treeview-item-container {
      height: auto;
      margin: 5px 0;

      /* changing the font color, and padding top and bottom (left is not recommended) */
      .checkbox {
        height: 18px;
        width: 18px;
        flex-shrink: 0;

        &.checked {
          background-color: rgb(27, 148, 247);
          background-image: url("../../../assets/images/white-tick.png");
          background-size: cover;
        }
      }

      .expand-button {
        font-size: 25px;
        flex-shrink: 0;
        font-family: Arial, Helvetica, sans-serif;
      }
      .checkbox.undetermined {
        background-color: blue;
        background-image: url("../../../assets/images/white-tick.png");
        background-size: cover;
      }

      .icon-and-text {
        font-size: 14px;
        // text-overflow: ellipsis;
        height: auto;
        overflow: hidden;
        color: black;

        /* changing the selected item properties */
        &.selected {
          background: none !important;
          border: none;
        }

        /* changing the icons of the folders expanded */
        .icon {
          display: none;
        }

        .text {
          height: auto;

          .text-inner {
            color: #727178;
            height: auto;
            white-space: pre-wrap;
          }
        }
      }

      .checkbox {
        background-color: white;
      }
    }
  }
}
