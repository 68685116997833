.datacollection_container {

    .totalcountheading {
        font-size: 14px;
        font-weight: 600;
    }

    .totalcountheadingheader {
        font-size: 16px;
        font-weight: 600;
        color: #4252AF;
        border-bottom: 1px dotted #ccc;
    }

    .rowclasstotalcount {
        margin-top: 20px;
    }

    .dropDownSelect1 {
        z-index: 101 !important;
    }

    .testdropdown__value-container {
        max-height: 300px !important;
        overflow-y: auto !important;
    }

    .dialog {
        box-shadow: 0 8px 6px -6px rgb(194, 192, 192);
        position: static;
        left: 20%;
        top: 10%;
    }

    .image {
        height: 300px;
    }




    #or {
        position: relative;
        width: 65%;
        // height: 50px;
        // line-height: 50px;
        text-align: center;
    }

    #or::before,
    #or::after {
        position: absolute;
        width: 250px;
        height: 1px;
        top: 15px;
        background-color: #aaa;
        content: '';
    }

    #or::before {
        left: 0;
    }

    #or::after {
        right: 0;
    }
}