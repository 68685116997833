.ContainerResizepanel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .TopRow {
    flex: 0 0 auto;
    text-align: center;
  }
  .BottomRow {
    flex: 1 1 auto;
  }
  
  .PanelResizePanel {
    display: flex;
    flex-direction: column;
  }
  
  .PanelContent {
    height: 100%;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    /* overflow: hidden; */
    border-radius: 0.5rem;
  }
  
  .ResizeHandleOuter {
    flex: 0 0 1.5em;
    position: relative;
    outline: none;  
    --background-color: rgba(255, 255, 255, 0.2);
  }
  .ResizeHandleOuter[data-resize-handle-active] {
    --background-color: rgba(255, 255, 255, 0.2);
  }
  
  .ResizeHandleInner {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    background-color: rgb(157 151 151 / 20%);
    transition: background-color 0.2s linear;
  }
  
  .ButtonResizepanel {
    color: #ffffff;
    background-color: #2a3343;
    border: 1px solid #18181a;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }
  .Button:hover {
    background-color: #454950;
  }
  
  .Link {
    display: block;
    color: #dcadff;
    margin-bottom: 0.5rem;
  }
  
  .Icon {
    width: 1em;
    height: 1em;
    position: absolute;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
  }
  