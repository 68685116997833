.navlink {
  // float: left;
  display: inline-block;
  vertical-align: middle;

  span {
    display: inline-block;
    width: 1px;
    background-color: #ffffff9c;
    height: 24px;
    // margin-top: 18px;
  }

  a {
    display: block;
    padding: 18px 8px;
    text-decoration: none;
    font-weight: 500;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    letter-spacing: 0.5px;
    font-size: 14px;
    color: #ffffff;
    &.active {
      background-color: rgba(255, 255, 255, 0.1);
      // -webkit-box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 1);
      // -moz-box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 1);
      // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 1);
    }
    &.inactive {
      // background-color:#587490;
      color:rgba(255, 255, 255, 0.4);
      cursor: none;
      pointer-events: none;
      // -webkit-box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 1);
      // -moz-box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 1);
      // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 1);
    }
    &:hover {
      text-decoration: none;
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

@media screen and (max-width: 1024px) {
  .navlink {
    a {
      padding: 8px 7px;
      font-size: 14px;
      &:hover {
        color: #4252AF;
      }
    }
  }
}
