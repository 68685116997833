.session-history-container {
    .left-side {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .session-synopsis-container.show {
            left: 3px;
            top: 60px;
        }
    }
    .right-side {
        width: 300px;
        min-height: calc(100vh - 130px);
        padding: 30px 10px;
        background-color: #ebf1ff;
        color: #303030;

        .history-list {
            overflow: auto;
            height: calc(100vh - 235px);

            .history {
                margin: 8px auto;
                margin-right: 8px;
                border: 1px solid blue;
                padding: 8px;
                border-radius: 8px;
                cursor: pointer;
                position: relative;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.6;
                }

                .MJXc-display {
                    display: inline-block;
                }

                p {
                    margin-bottom: 0px;
                }

                .svgedit {
                    position: absolute;
                    right: 35px;
                    top: 8px;
                }
                .svgdelete {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }
                svg {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }

                .green {
                    font-weight: bold;
                    color: green;
                    margin-bottom: 0px;
                }

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px,
                        rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}
.sessionquestion{
    margin-left: "2px";    
    
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}
