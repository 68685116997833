.upcoming-interactions-container {
    // padding: 10px;
    // border-radius: 10px;
    // stroke-width: 1;
    // background-image: url('../../../../../assets/images/Rectangle.svg');
    // background-repeat: round;
    // background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;    
    background-color: #ffffff;
    border: 1px solid #ccc;
    .video-title {
        svg {
            cursor: pointer;
        }
    }

    .content {
        max-height: 70vh;
        overflow: auto;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: #c8c8c8;
            cursor: pointer;
            &.hover {
                cursor: pointer;
            }
        }

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
            background-color: grey;
            &.focus {
                cursor: pointer;
            }
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: #aeaeae;
            cursor: pointer;
            &.hover {
                cursor: pointer;
            }
        }

        .slide {
            padding: 8px;
             position: relative;

            &:hover {
                .slide-hover-content {
                    display: flex;
                }
            }

            .slide-content {
                display: flex;
                width: fit-content;
                align-items: center;
                color: #000;
                cursor: pointer;
                flex: 1;

                &.visited {
                    color: green;
                }

                &.current {
                    color: #0000ff;
                }
                &:hover {
                    color: #0056b3;
                    text-decoration: underline;
                }

                .time {
                    margin-right: 8px;
                    margin-left: 8px;
                }
            }

            .slide-hover-content {
                display: none;
                position: absolute;
                background-color: #fff;
                border-radius: 5px;
                z-index: 1;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
                padding: 8px;
                right: 18px;
                left: 55px;
                pointer-events: none;
            }
        }

        &.hide-content {
            display: none;
        }
    }
}
