$header_background_color: #4252af;

.header {
  width: 100%;
  // height: 60px;
  // padding: 10px 0;
  margin: 0 auto;
  background-color: $header_background_color;

  .course-name-container {
    display: flex;
    align-items: center;
    // padding-right: 15px;
    // padding-left: 15px;
    // margin-left: -15px;
    cursor: pointer;
    position: relative;

    &:hover {
      color: black;
      // background-color: rgba(0, 0, 0, 0.1);

      .course-list {
        visibility: visible;
      }
    }

    .course-name {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      padding: 10px 0;

      &:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }

    .course-list {
      cursor: default;
      // visibility: hidden;
      // position: absolute;
      z-index: 7;
      top: 40px;
      // border: 1px solid rgba(0, 0, 0, 0.1);
      margin-left: -20px;
      // -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      // -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      // box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      padding-left: 15px;
      // background-color: #fff;
      text-align: left;
      // border-radius: 5px;

      ul {
        margin-bottom: 0px;

        li {
          cursor: default;
          text-wrap: nowrap;
          padding: 5px 0;
        }
      }

      .css-yss0bv-MuiInputBase-root-MuiInput-root-MuiSelect-root {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 14px;
        // letter-spacing: 0.5px;
        font-weight: 500;
        padding-top: 5px;

        &::before {
          border: 0 !important;
        }
      }

    }
  }
}


.logo {
  // width: 130px;
  // height: 60px;
  align-self: center;

  a {
    height: auto;
    width: 100%;
    text-align: left;
    margin: 0px;
    padding: 0px 20px 0 16px;
    display: flex;

    img {
      max-width: 130px;
    }
  }
}

.content-right {
  margin-left: auto;
  height: inherit;
  display: flex;
  vertical-align: middle;
}

.linkbuttonheader {
  background-color: transparent;
  padding: 0;
  color: #fff;
  font-weight: 400;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

}

.linkcss {
  color: #007bff;
  font-weight: 600;
}

.linkcsslight {
  color: #62b4eb;
  font-weight: normal;
}

.linkcsslighter {
  color: #979595;
  font-weight: normal;
}

.MuiPopover-root {
  z-index: 9999 !important;
}

@media screen and (max-width: 1366px) {
  .logo {
    a {
      img {
        max-width: 115px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .content-right {
    padding-right: 40px;
  }

  .header {
    .course-name-container {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .logo {
    a {
      img {
        max-width: 100px;
      }
    }
  }
}

@media screen and (max-width: 992px) {}

@media screen and (max-width: 576px) {
  .header {
    // .course-name-container {
    //   padding-left: 35px;
    // }

    .course-name {
      display: none;
    }
  }

  .logo {
    a {
      padding: 10px 10px 10px 6px;
    }

  }


}

.menuLink {
  display: inline-block !important;
  width: 1px;
  background-color: #ffffff9c;
  height: 24px;
}

.feedbackDialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 650px;
  }
}