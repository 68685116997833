.teacher-ui {
    .title {
        width: 100%;
    }

    .yellow-btn {
        width: fit-content;
    }

    .conferences {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0px;
        width: 100%;

        .conference {
            border-radius: 5px;
            border: solid 1px #e4e4e4;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            width: 350px;
            padding: 16px;
            text-decoration: none;
            margin: 5px;

            p {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .student {
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0.35px;
                color: #3c4049;
                margin-bottom: 5px;
            }

            .tutor {
                font-size: 13px;
                letter-spacing: 0.35px;
                color: #3c4049;
                margin-bottom: 5px;
            }

            .question {
                font-size: 13px;
                letter-spacing: 0.35px;
                color: #3c4049;
                display: flex;
                margin-bottom: 5px;
            }

            .questiontext {
                margin-left: "2px";
                color: #3c4049;
                font-size: "12px";
                display: -webkit-box;
                max-width: 400px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }

            .availability {
                margin: 0px;

                &.active {
                    color: green;
                    background-color: #f5f6ff;
                }
            }

            &:hover {
                border: solid 1px #2f4bff;
                background-color: #f5f6ff;
            }

            &.active {
                border: solid 1px #2f4bff;
                background-color: #f5f6ff;
            }

            &.inactive {
                background-color: #f8f8f8;

                &:hover {
                    border: solid 1px #e4e4e4;
                    background-color: #f8f8f8;
                }
            }
        }

        label {
            font-size: 14px;
            letter-spacing: 0.25px;
            color: #727178;
            justify-content: center;
            width: 100%;
            display: flex;
        }
    }
}

.spanOnline {
    margin-right: 10px;
    font-weight: bold;
    color: #0A9743;   
    font-size: 10px;
    padding: 4px 10px; 
    background-color: #E2F3E8;
    border-radius: 10px;
}
.spanOffline {
    margin-right: 10px;
    font-weight: bold;
    color: #8B8B8B;   
    font-size: 10px;
    padding: 4px 10px; 
    background-color: #E6E6E6;
    border-radius: 10px;
}
.spanCompleted {
    margin-right: 10px;
    font-weight: bold;
    color: #007BFF;   
    font-size: 10px;
    padding: 4px 10px; 
    background-color: #EDF6FE;
    border-radius: 10px;
}
.availability{
    a{
        color: #164B99;
        font-weight: bold;
    }
}

.MuiSvgIcon-root {
//  margin-left: -5px !important;
}
