.container {
    flex-grow: 1;

    .curiosity-left-container {
        border-right: 1px solid #bfd4ff;
        padding-top: 0px !important;
    }

    .curiosity-right-container {
        area {
            cursor: pointer;
        }

        .curriculum-image {
            width: 100%;
            margin-top: 40px;
            cursor: pointer;
            pointer-events: auto;
        }
    }
}
