// .contentContainer {
//     max-width: 100% !important;
//     margin: 0 auto;
//     span{
//         max-height: 200px;
//         min-height: 100px;
//         overflow-y: auto;
//         font-size: 16px;
//         line-height: 22px;
//         display: block;
//         padding-top: 10px;
//         // padding-left: 35px;
//     }
// }

.mainLineText {
    font-size: 28px !important;
    text-transform: uppercase;
    font-weight: 100;
    color: #4252AF;
    line-height: 1.1 !important;
    margin-bottom: 0;
}

.brainOutlineImg {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 0px;
    position: relative;
    padding-bottom: 20px;
}

.statementBtns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 0em;
    margin-top: 20px;

    button {
        font-size: 16px;
    }

    textarea {
        border: 1px solid #ccc;
        background: #fff;
        font-size: 16px;
    }

    .browseImageBtn {
        border: 1px solid #4252AF;
        border-radius: 4px;
        padding: 15px 7px;
        font-weight: 500;
        color: #4252AF;
        cursor: pointer;
        text-align: center;
        margin: 0;
        background: #ffffff;
        text-wrap: nowrap;
    }
}

.actionBtns {
    display: flex;   
    gap: 10px;
    justify-content: space-between;
    margin-right: 8px;

    .submitProbBtn {
        font-size: 16px;
        width: fit-content;
        padding: 10px 14px;
        margin-left: 0;
    }


    .uploadScanBtnsContainer {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
        align-items: center;

        button {
            background: white;
            border: 1px solid #4252AF;
            color: #4252AF;
            padding: 9px 10px;
            // width: 50%;
            text-wrap: nowrap;
        }

        .uploadScanBtns {
            display: flex;
            align-items: center;

            div {
                display: flex;
            }
        }

        .uploadImgHolder {

            // width: 50%;
            // height: 37px;
            .imageUploadInput {
                width: 0px;
            }
        }
    }
}

.backlinkbutton {
    background-color: transparent;
    // padding: 0;
    color: #4252AF;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    // position: absolute;
    /* right: 0; */
    /* z-index: 10; */
    // top: 22px;
    /* border: 1px solid; */
    padding: 0px 3px;
    cursor: pointer;

    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
    }

}

.linkbutton11 {
    background-color: transparent;
    padding: 0;
    color: #4252AF;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 10;
    top: 12px;
    border: 1px solid;
    padding: 5px 10px;
    width: 150px;
    border-radius: 15px !important;

    &:hover {
        text-decoration: none;
    }
}

.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}

.wirisEditorContainer {
    height: calc(100vh - 266px);

    .wrs_toolbar {
        margin-top: -26px;
    }
}

// .wrs_toolbar{
//     display: none !important;
// }
// .EditDel{
//     padding-top: 20px;
// }

.morehintbuttons {
    display: block;
    opacity: 1;
    /* visibility: hidden; */
    position: absolute;
    height: 220px;
    width: 50px;
    padding: 5px 2px;
    background: #ECF1FE;
    border-radius: 25px;
    left: 0;
    top: -220px;
    bottom: -6px;
    box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
}

.mcqoptionscards {
    // background-color: #fff;
    // border: 1px solid #e0e4e8;
    // border-radius: 4px;
    // box-shadow: 0px 8px 24px 0px #18181833;
    padding: 10px
}

@media screen and (max-width:768px) {
    .brainOutlineImg {
        padding-top: 30px;
        flex-direction: column-reverse
    }

    .statementBtns {
        padding-top: 2em;
    }

    .actionBtns {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;

        // justify-content: space-between;
        .submitProbBtn {
            width: 100%;
        }

        .uploadScanBtnsContainer {
            flex-direction: column;

            .uploadScanBtns {
                display: flex;
                // align-items: flex-start;
                flex-direction: column-reverse;

                div {
                    display: inline;
                    // align-items: flex-start;
                    // justify-content:left;
                }

                p {
                    padding-bottom: 20px;
                }
            }
        }
    }
}