.box-outer {
  border-bottom: 1px solid rgba(0, 43, 112, 0.3);
  box-shadow: 0 0.05em 0 0 rgba(255, 255, 255, 0.25);
  margin-bottom: 20px;
  padding-bottom: 10px;

  .title {
    font-size: 18px;
    border-bottom: none;
  }
}
