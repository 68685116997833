.notes {
  cursor: pointer;
    font-size: 14px;
    display: flex;
    padding: 10px;
    // border-radius: 10px;
    stroke-width: 1;
    /* background-image: url(/static/media/Rectangle.eb1b1d94.svg); */
    /* background-repeat: round; */
    background: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    /* background-repeat: no-repeat; */
    border: 1px solid #e3e3e3;
    &.active{
      background-color: #ffffff;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    }
    &:hover{
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    }
  .chapter {
    margin-left: 50px;
  }

  .chapter-text {
    padding: 20px 0 10px 0;
    width: calc(100% - 50px);

    img {
      position: absolute;
      width: 40px;
    }

    .text-container {
      max-height: 72px;
      //overflow: hidden;

      .chp-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 7px;
        color: #212529;
        text-decoration: none solid rgb(33, 37, 41);
        //line-height: 32px;
      }

      .chp-description {
        font-size: 13px !important;
        display: -webkit-box;
        max-width: 400px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 7px;
      }

      p {
        //overflow: hidden;
        text-overflow: ellipsis;
        //line-height: 1.5em;
        margin-bottom: 0;
        font-size: 16px;
        color: #212529;
        text-decoration: none solid rgb(33, 37, 41);
        //line-height: 32px;
      }
    }

    .date {
      color: #a1a1a1;
      font-style: italic;
      display: inline-block;
      margin-top: 10px;
      line-height: 1.5em;
      margin-bottom: 0.5em;      
      font-size: 16px;          
      text-decoration: none solid rgba(33, 37, 41, 0.5);
      line-height: 32px;
    }

    .review {
      display: inline-block;
      // float: right;
      width: calc(100% - 60px);
      margin-top: 10px;
      color: #a1a1a1;
      font-style: italic;
      text-align: right;
      line-height: 1.5em;
      margin-bottom: 0.5em;
    }
  }
}

ul {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 20px;
}

.active {
  background-color: #fff;
  position: relative;

  .chapter-text {
    margin-right: 40px;

    &::after {
      content: "";
      background: url(../../../../assets/images/icon_right_arrow-1.png);
      position: absolute;
      width: 24px;
      height: 44px;
      right: 5px;
      top: calc(50% - 22px);
    }
  }
}