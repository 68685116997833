.home-curriculum-content-card {
    .title {
        border-bottom: 1px solid #ccc;
        margin: 0;
        // padding-bottom: 15px;
    }
    .content-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        a {
            // transition: all 200ms linear;
            // color: black;
            // max-width: 380px;
            // padding: 10px;
            // margin-bottom: 8px;
            // margin-right: 15px;
            width: 100%;
            padding: 10px;
            border-bottom: 1px solid #ccc;
            .video-thumb {
                float: left;
                margin-right: 10px;
                width: 120px;
                overflow: hidden;
                max-height: 100px;
                height: 90px;
                img {
                    width: inherit;
                    border-radius: 10px;
                }
            }
            .content-card-text {
                float: left;
                width: calc(100% - 130px);
                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 12px;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                p {
                    line-height: 1.3em;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: .4rem;
                }
                .interactive-video-icon {
                    position: relative;
                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        a:hover {
            -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
            -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
                text-decoration: none;
                border: 1px solid #ccc;
                border-top: 0px;
                z-index: 1;
        }
    }
    .alpha-data-label {
        font-size: 10px;
        font-weight: bold;
        color: #AEAEAE;
        text-transform: uppercase;
        margin-right: 16px !important;
      }
}