.content-options-popup {
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: absolute;
    background: white;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;
    border-radius: 4px;
    max-width: 500px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    .curriculum-content-card {
        .title {
            padding-bottom: 4px;
        }
        .content-card {
            a {
                border-radius: 4px;
                margin-bottom: 0px;
                padding: 4px;

                .content-card-text {
                    h3 {
                        margin-bottom: 0.3rem;
                    }
                }
            }
        }
    }

    &.show-content-options-popup {
        display: flex;
    }

    &.hide-content-options-popup {
        display: none !important;
    }
}
