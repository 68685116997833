.session-synopsis-container {
    position: fixed;
    top: 20px;
    height: 80%;
    width: 65%;
    transition: all ease-in-out 0.2s;
    background-color: white;
    z-index: 150;
    overflow-y: hidden;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerContainer {
        display: flex;

        .closeButton {
            border: none;
            outline: none;
            background: transparent;
            position: absolute;
            top: 10px;
            right: -8px;

            [class^="ico-"],
            [class*=" ico-"] {
                font: normal 1em/1 Arial, sans-serif;
                display: inline-block;
            }

            .ico-times:before {
                content: "\2716";
                color: #000000;
            }
        }


    }

    .data {
        height: 90%;
        display: flex;
        width: 100%;
    }

    .left-part {
        overflow-y: auto;
        width: 40%;
        margin-right: 10px;
    }

    .right-part {
        flex: 1;
        overflow: auto;
        display: flex;
        width: 60%;
        flex-direction: column;
        align-items: flex-start;
        border: 8px solid black;
        background-color: white;
        padding: 12px;

        .labeltextnode {
            font-size: 11px;
            color: #000000;
            // margin-bottom: 2px;
            font-weight: 500 !important;
            text-transform: none;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .form-group {
                width: 100%;

                .labeltext {
                    font-size: 12px;
                    color: #000000;
                    margin-bottom: 2px;
                }

                .bg-yellow {
                    background: rgb(255, 255, 138);
                }

                .bg-grey {
                    background: #c0c0c0;
                }

                .bg-green {
                    background: #bdd8b2;
                }

                .bg-darkgreen {
                    background: #74ba56;
                }

                .bg-ligtBlue {
                    background: #bfd4ff;
                }

                .bg-blue {
                    background: #5897fb;
                }

                .bg-pink {
                    background: #bdd8b2;
                }

                .bg-purple {
                    background: #ffc1c1;
                }
            }

            button {
                width: fit-content;
            }

            label {
                color: red;
                margin-top: 8px;
            }

            .btn-group {
                justify-content: space-evenly;
                width: 100%;
                margin-top: 12px;
            }

            .highlight {
                cursor: pointer;
            }

            .highlight-yellow {
                background: rgb(255, 255, 138);
                padding: 0.1rem;
            }

            .highlight-green {
                background: #bfd4ff;
                padding: 0.1rem;
            }

            .highlight-grey {
                background: #c0c0c0;
                padding: 0.1rem;
            }

            .highlight-blue {
                background: #5897fb;
                padding: 0.1rem;
            }

            .highlight-pink {
                background: #bdd8b2;
                padding: 0.1rem;
            }

            .highlight-purple {
                background: #ffc1c1;
                padding: 0.1rem;
            }

            .highlight-transparent {
                // background: #00000009;
                padding: 0.1rem;
            }

            .has-note::before {
                font-family: "Material Icons";
                content: "comment";
                padding-right: 0.2rem;
                color: black;
            }

            .highlight-transparent::before {
                font-family: "Material Icons";
                content: "comment";
                padding-right: 0.2rem;
                color: #fdbd17;
            }
        }

        .synopsis-equation {
            display: block;
            margin: 8px;
        }

        button {
            align-self: center;
        }

        .sample-data-label {
            width: 100%;
        }
    }

    h3 {
        font-weight: bold;
    }

    .desc {
        font-weight: bold;
    }

    .red {
        color: red;
        font-style: italic;
    }

    &.show {
        left: 4%;
    }

    &.hide {
        display: none;
    }

    &.editable {
        .content {
            flex: 1;
        }

        .html-content {
            width: 100%;
        }
    }

    .resourcepanel {
        background-color: #fcfcfc;
        //margin-top: 10px;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
}


@media (max-width: 1366px) {
    .buttoncss {
        font-size: 12px !important;
        
    }
    .session-synopsis-container .desc
    {
        font-size: 12px !important;
    }
}