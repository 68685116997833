.fieldHeaderText {
    font-weight: bold;
}

.interactionTextwithMargin {
    margin-bottom: 20px;
}

.interactionEquations {
    margin-bottom: 0px;
    display: inline-block;
}

.interactionText {
    margin-bottom: 0px;
}

.pageHeader {
    font-weight: 600;
    font-size: 32px;
    color: black;
    margin: 10px;
    margin-bottom: 40px !important;
}

.interactiveTable {

    width: 100%;

    .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
        font-weight: bold;
        resize: horizontal;
        overflow-x: overlay;
        overflow-y: hidden;
        border-right: 1px solid;
    }
}