.whiteboard-container {
    display: flex;
    position: relative;
}

.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}

.answer-board-holder {
    margin: 0px;
    width: 100vw;
    // margin-right: 15px;
}

.vc-container {
    width: 0;
    display: flex;
    opacity: 0;
    // transform: translateX(300px);
    transition: all 200ms linear;
}

.modal--visible {
    width: 400px;
    opacity: 1;
    transform: translateX(0px);
    position: fixed;
    /* right: 0px; */
    left: calc(50vw - 200px);
    top: 80px;
    z-index: 2;
}

.modal--invisible {
    width: 0;
    opacity: 0;
}

.disabledDivText {
    pointer-events: none;
    opacity: 0.2;
}

.bg-white-with-shadow11 {
    background-color: #fff;
    border: 1px solid #e0e4e8;
    border-radius: 16px;
    -webkit-box-shadow: 0px 8px 24px 0px #18181833;
    -moz-box-shadow: 0px 8px 24px 0px #18181833;
    box-shadow: 0px 8px 24px 0px #18181833;
    padding: 50px 50px !important;
    position: absolute;
    top: 300px;
    z-index: 999;
    opacity: 3;
    //left: 100px;
    left: 20%;
    width: 40%;

    @media only screen and (max-width: 600px) {
        left: 15% !important;
        width: 70% !important;
    }

}
