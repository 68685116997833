.breadcrumb {
    background-color: #f1f1f1 !important;
    color: #444444;
    justify-content: flex-start;
    margin-bottom: 0 !important;
    border-radius: 0;
    padding: 8px 0px !important;
    float: left;
    margin-right: -15px;
}

.breadCrumbContainer {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.extended-breadcrumb {
    align-items: center;
    flex-flow: row wrap;
    align-content: space-between;

    .link {
        color: #007bff;
        text-decoration: none solid rgb(33, 37, 41);;
        padding-right: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
    }

    .link:hover {
        color: #0056b3;
        text-decoration: underline;
    }

    span {
        color: #888888;
        font-size: 16px;
        color: #212529;
        text-decoration: none solid rgb(33, 37, 41);
        line-height: 32px;
    }

    svg {
        margin-right: 4px;
    }
}

.breadcrumb-link {
    color: #444444;
    text-decoration: none;
    padding-right: 5px;

    &:not(:last-child) {
        &:after {
            content: "|";
            padding-left: 5px;
        }
    }
}
