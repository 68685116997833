.instructional-content-challenge {
  color: black !important;
  text-decoration: none !important;


  .challenge-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .video-thumb {
    float: left;
    margin-right: 10px;
    width: 120px;
    overflow: hidden;
    margin-bottom: 15px;

    img {
      width: 100%
    }
  }

  .challenge-text {
    float: left;
    width: calc(100% - 130px);

    h3 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 12px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }



    .descriptiontext {
      font-size: 13px !important;
      display: -webkit-box;
      max-width: 500px;
      -webkit-line-clamp: 5;
      //  -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;      
    }

    p {
      // line-height: 1.3em;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // height: 60px;
      height: 60px;
      font-size: 13px !important;
      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}