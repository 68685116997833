@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";


.curriculum-container {
  //overflow-x: hidden;
  background-color: #f3f3f3;
  max-width: 100% !important;
  // padding-left: 20px !important;
  // padding-right: 20px !important;
  // margin-left:20px !important;
  // margin-right:20px !important;
  // max-width: 100% !important;

}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  //border-bottom: 1px solid #dadada;
  toggling-btn .main-row {
    height: 100%;


    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  .cardsContainer {
    margin-top: 0 !important;
  }

  .challenge-card {
    transition: all 200ms linear;
    background: white;
    border-radius: 10px;
    border: 1px solid white;
    margin-left: 15px;
  }

  .challenge-card:hover {
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
  }

  .hint {
    background: #fdbd17bb;
    padding: 0.1rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .hint:hover {
    background: #fdbd1799;
    border: 1px dotted;
    cursor: default;
  }

  .highlight {
    cursor: pointer;
  }

  .highlight-yellow {
    background: rgb(255, 255, 138);
    padding: 0.1rem;
  }

  .highlight-green {
    background: #bfd4ff;
    padding: 0.1rem;
  }

  .highlight-blue {
    background: #5897fb;
    padding: 0.1rem;
  }

  .highlight-pink {
    background: #bdd8b2;
    padding: 0.1rem;
  }

  .highlight-purple {
    background: #ffc1c1;
    padding: 0.1rem;
  }

  .highlight-transparent {
    // background: #00000009;
    padding: 0.1rem;
  }

  .has-note::before {
    font-family: "Material Icons";
    content: "comment";
    padding-right: 0.2rem;
    color: black;
  }

  .highlight-transparent::before {
    font-family: "Material Icons";
    content: "comment";
    padding-right: 0.2rem;
    color: #fdbd17;
  }

  .curriculum-tab {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #dadada;

  }

  .curriculum-tab li {
    float: left;
    display: flex;
    vertical-align: middle;
    height: 39px;
  }

  .curriculum-tab li span {
    display: inline-block;
    width: 1px;
    background-color: #707070;
    height: 26px;
    margin-top: 7px;
  }

  .curriculum-tab li a {
    padding: 8px 25px 8px 15px;
    text-decoration: none;
    font-size: 14px;
    color: #444444;
    line-height: 24px;
    display: flex;
  }

  .curriculum-tab li a img {
    margin-right: 7px;
  }

  .right-container {
    //background-color: #bfd4ff;
    padding-bottom: 20px;
    display: none;
    margin-top: 25px;


    .toggling-btn {
      position: absolute;
      left: -19px;
      top: 0px;

      span {
        cursor: pointer;
        font-size: 12px;
        z-index: 100;
        background-color: #bfd4ff;
        padding: 5px;
        color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }

  .content-container {
    margin-left: 0;
    margin-right: 0;
  }

  .open {
    display: inline;
  }

  .left-container {
    padding: 20px 20px 20px 0;
    flex: 0 0 95%;

  }

  .left-container-open {
    padding: 20px 20px 20px 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .left-container-open-videocall {
    padding: 20px 20px 20px 0;
    max-width: 100%;
  }

  .arrow {
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    // top: 70px;
    // right: 5px;
    top: 195px;
    right: 70px;
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    // top: 30px;
    // right: 0;
    top: 175px;
    right: 80px;
  }

  .action-buttons {
    cursor: pointer;
  }

  .disabled-action {
    opacity: 0.5;
    cursor: default !important;
    pointer-events: none;
  }
}

.toggleContainer {
  background-color: transparent;
  padding-top: 20px;
  cursor: pointer;
  //display: flex;
  //justify-content: end;

  .left {
    justify-content: start;
  }

}

.vc-containercur {
  width: 0;
  display: flex;
  opacity: 0;
  transform: translateX(300px);
  transition: all 200ms linear;
}

.modal--visiblecur {
  width: 100%;
  opacity: 1;
  transform: translateX(0px);
}

.modal--invisiblecur {
  width: 0;
  opacity: 0;
}

.whitebackground {
  background-color: #fff;
  margin-top: -55px;
}

.transparentbackground {
  background-color: transparent;
}

.openLefrContainerIcon {
  position: absolute !important;
  right: 5px;
  top: -55px;
  flex: 0 0 3% !important;
  max-width: 30px !important;
}

@include media-breakpoint-down(md) {
  .container {
    //width: 100% !important;

    .right-container {
      position: absolute;
      right: 0px;
      transform: translateX(100%);
      transition: all 0.75s ease;

      .toggling-btn {
        visibility: visible;
      }
    }

    .open {
      transform: translateX(0);
    }
  }
}

@media (max-width: 1366px) {
  .curriculum-container {
    // overflow-x: hidden;    
    // padding-left: 20px !important;
    // padding-right: 20px !important;
    // margin-left:20px !important;
    // margin-right:20px !important;
    max-width: 96% !important;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    // top: 70px;
    // right: 5px;
    top: 195px;
    right: 70px;
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    // top: 30px;
    // right: 0;
    top: 175px;
    right: 80px;
  }
}

.toggledfront {
  display: block !important;
}

@media (max-width: 1024px) {
  .curriculum-container {
    // overflow-x: hidden;
    background-color: #f3f3f3;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    max-width: 100% !important;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    // top: 70px;
    // right: 5px;
    top: 195px;
    right: 70px;
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    // top: 30px;
    // right: 0;
    top: 175px;
    right: 80px;
  }
}