.stepContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        min-width: 290px;
    }

    .linkTextEdit {
        background-color: #4252AF !important;
        color: white !important;
    }

    .linkText {
        color: blue;
        text-decoration: underline;
        cursor: pointer;

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
            font-size: small;
        }
    }

    a {
        color: blue;
    }

    hr {
        display: none;
    }

    .advanceStepNote {
        font-size: 14px;
        font-style: italic;
        margin-bottom: 5px;
    }

    .inputOptionsContainer {
        background: #f0f0f0;
        margin-bottom: 5px;
        border: 1px solid #f0f0f0;
        border-radius: 10px
    }

    .lineInfoContainer {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin-bottom: 5px;

        .lineLabel {
            padding-left: 10px;
            font-size: larger;
        }

        .expandCollapse {
            color: blue;
            text-decoration: underline;
            cursor: pointer;

        }

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
            .lineLabel {
                padding-left: 5px;
                font-size: small;
            }
        }
    }

    .inputContainer {
        width: 100%;
    }

    .wirisSaveOptions {
        display: flex;
        justify-content: center;
        gap: 15px;
        padding-bottom: 5px;

        span {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
            font-size: large;
        }
    }

    .equationBox {
        display: flex;
        width: 100%;

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
            min-width: 220px;
        }

        .equationContainer {
            width: 100%;
            height: 50px;
            background-color: #f5f5f7;
            border: 1px solid #f5f5f7;
            border-radius: 10px;
            overflow-y: hidden;

            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
                overflow-x: auto;
                margin-left: -20px;
                min-width: 220px;
            }
        }
    }

    .editDeleteButtonContainer {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
    }

    .feedbackOptionContainer {
        display: flex;
        justify-content: left;
        justify-content: space-between;
        //margin-left: 5px;

        .feedbackToggleButtonContainer {
            display: flex;
        }

        .equationStatusContainer {
            display: flex;
            justify-content: end;
            grid-gap: 0;

            span {
                text-decoration: none;
                margin-top: 5px;
            }
        }

        svg {
            display: flex;
            justify-content: flex-start;
            color: blue;
            margin-top: 5px;
            margin-right: 4px;
        }

        span {
            display: flex;
            justify-content: flex-start;
            color: blue;
            text-decoration: underline;
            font-style: italic;
            cursor: pointer;
        }
    }

}