@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.user-profile {
  display: flex;
  align-items: center;
  position: relative;
  left: -15px;

  a {
    font-size: 13px;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;

    label {
      margin: auto 0;
      color: #fff;
      margin-right: 3px;
    }

    img {
      margin: 0 10px 0 3px;
      width: 35px;
      height: 35px;
      border-radius: 70px;
      border: 1px solid #fff;
    }
  }

  &:hover {
    .user-profile-menu {
      visibility: visible;
    }
  }
}

.user-profile-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  visibility: hidden;
  width: 100%;
  right: 5px;

  @include media-breakpoint-down(xl) {
    width: 170px;
  }

  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  z-index: 1000;
  top: 35px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);

  a {
    text-decoration: none !important;
    padding: 10px;
    color: #271d04;
    display: block;
    font-size: 15px;
    width: 100%;
    text-align: center;
  }

  .disabled-link {
    pointer-events: none;
  }

  a:hover {
    text-decoration: none;
    color: black;
    background-color: rgba(00, 00, 00, 0.1);
  }

  &::after {
    content: "";
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -8px;
    right: 14px;
  }

  .dropdown {
    text-decoration: none !important;
    padding: 0px !important;
    color: #271d04 !important;
    display: block !important;
    font-size: 15px !important;
    width: 100% !important;
    text-align: center !important;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    z-index: 100;
    top: 60px;
    border: none;
    // -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    // -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    // box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  }
}

.btn-info {
  color: #000 !important;
  background-color: #fdbd17 !important;
  border-color: #fdbd17 !important;
}

@media only screen and (max-width:576px) {
  .user-profile {
    a {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  // .user-profile-label {
  //   display: none;
  //   visibility: hidden;
  // }

  .user-profile-menu {
    // left: -30px
  }
}

.user-profile-label {
  font-size: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  // .user-profile-label {
  //   display: none;
  //   visibility: hidden;
  // }
}

@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  // .user-profile-label {
  //   display: none;
  //   visibility: hidden;
  // }
}