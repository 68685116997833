.MathJax_Display {
    text-align: center;
    margin: 1em 0em;
    position: relative;
    display: block !important;
    text-indent: 0;
    max-width: none;
    max-height: none;
    min-width: 0;
    min-height: 0;
    width: 100%;
}

.MathJax .merror {
    background-color: #ffff88;
    color: #cc0000;
    border: 1px solid #cc0000;
    padding: 1px 3px;
    font-style: normal;
    font-size: 90%;
}

.MathJax .MJX-monospace {
    font-family: monospace;
}

.MathJax .MJX-sans-serif {
    font-family: sans-serif;
}

#MathJax_Tooltip {
    background-color: InfoBackground;
    color: InfoText;
    border: 1px solid black;
    box-shadow: 2px 2px 5px #aaaaaa;
    -webkit-box-shadow: 2px 2px 5px #aaaaaa;
    -moz-box-shadow: 2px 2px 5px #aaaaaa;
    -khtml-box-shadow: 2px 2px 5px #aaaaaa;
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color='gray', Positive='true');
    padding: 3px 4px;
    z-index: 401;
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    display: none;
}

.MathJax {
    display: inline;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 100%;
    font-size-adjust: none;
    text-indent: 0;
    text-align: left;
    text-transform: none;
    letter-spacing: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    float: none;
    direction: ltr;
    max-width: none;
    max-height: none;
    min-width: 0;
    min-height: 0;
    border: 0;
    padding: 0;
    margin: 0;
}

.MathJax:focus,
body :focus .MathJax {
    display: inline-table;
}

.MathJax.MathJax_FullWidth {
    text-align: center;
    display: table-cell !important;
    width: 10000em !important;
}

.MathJax img,
.MathJax nobr,
.MathJax a {
    border: 0;
    padding: 0;
    margin: 0;
    max-width: none;
    max-height: none;
    min-width: 0;
    min-height: 0;
    vertical-align: 0;
    line-height: normal;
    text-decoration: none;
}

img.MathJax_strut {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    vertical-align: 0 !important;
}

.MathJax span {
    display: inline;
    position: static;
    border: 0;
    padding: 0;
    margin: 0;
    vertical-align: 0;
    line-height: normal;
    text-decoration: none;
    box-sizing: content-box;
}

.MathJax nobr {
    white-space: nowrap !important;
}

.MathJax img {
    display: inline !important;
    float: none !important;
}

.MathJax * {
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}

.MathJax_Processing {
    visibility: hidden;
    position: fixed;
    width: 0;
    height: 0;
    overflow: hidden;
}

.MathJax_Processed {
    display: none !important;
}

.MathJax_test {
    font-style: normal;
    font-weight: normal;
    font-size: 100%;
    font-size-adjust: none;
    text-indent: 0;
    text-transform: none;
    letter-spacing: normal;
    word-spacing: normal;
    overflow: hidden;
    height: 1px;
}

.MathJax_test.mjx-test-display {
    display: table !important;
}

.MathJax_test.mjx-test-inline {
    display: inline !important;
    margin-right: -1px;
}

.MathJax_test.mjx-test-default {
    display: block !important;
    clear: both;
}

.MathJax_ex_box {
    display: inline-block !important;
    position: absolute;
    overflow: hidden;
    min-height: 0;
    max-height: none;
    padding: 0;
    border: 0;
    margin: 0;
    width: 1px;
    height: 60ex;
}

.MathJax_em_box {
    display: inline-block !important;
    position: absolute;
    overflow: hidden;
    min-height: 0;
    max-height: none;
    padding: 0;
    border: 0;
    margin: 0;
    width: 1px;
    height: 60em;
}

.mjx-test-inline .MathJax_left_box {
    display: inline-block;
    width: 0;
    float: left;
}

.mjx-test-inline .MathJax_right_box {
    display: inline-block;
    width: 0;
    float: right;
}

.mjx-test-display .MathJax_right_box {
    display: table-cell !important;
    width: 10000em !important;
    min-width: 0;
    max-width: none;
    padding: 0;
    border: 0;
    margin: 0;
}

.MathJax .MathJax_HitBox {
    cursor: text;
    background: white;
    opacity: 0;
    filter: alpha(opacity=0);
}

.MathJax .MathJax_HitBox * {
    filter: none;
    opacity: 1;
    background: transparent;
}

#MathJax_Tooltip * {
    filter: none;
    opacity: 1;
    background: transparent;
}

@font-face {
    font-family: MathJax_Main;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Main-Regular.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Main-Regular.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Main-bold;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Main-Bold.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Main-Bold.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Main-italic;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Main-Italic.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Main-Italic.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Math-italic;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Math-Italic.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Math-Italic.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Caligraphic;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Caligraphic-Regular.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Caligraphic-Regular.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Size1;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Size1-Regular.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Size1-Regular.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Size2;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Size2-Regular.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Size2-Regular.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Size3;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Size3-Regular.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Size3-Regular.otf?V=2.7.5")
            format("opentype");
}

@font-face {
    font-family: MathJax_Size4;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/woff/MathJax_Size4-Regular.woff?V=2.7.5")
            format("woff"),
        url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/fonts/HTML-CSS/TeX/otf/MathJax_Size4-Regular.otf?V=2.7.5")
            format("opentype");
}

.MathJax .noError {
    vertical-align: ;
    font-size: 90%;
    text-align: left;
    color: black;
    padding: 1px 3px;
    border: 1px solid;
}
