.graphContainer {
    height: 100%;
    width: 100%;
}

.chartContainer {
    width: 100%;
    height: 100%;
    position:absolute;
   // border: 1px solid lightgray;
    background-color: #BFD4FF;
  }

.nodeContainer {
    width: 50px;
    height: 50px;
    position: absolute;
    left: -100px;
    top: 0;
}
.graph-progress-bar-container {
    display: flex;
    position: absolute;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    align-items: center;
  }

// .vis-tooltip {
//     display: none !important;
// }

