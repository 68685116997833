.html-content {
    line-height: 1.4rem;
    text-align: justify;
    position: relative;
    height: 100%;


    img {
        max-width: 50vw;
        max-height: 60vh;
    }

    .os-table {
        // overflow-x: auto;
        margin: 20px 0;

        table {
            margin: 0;
            border-collapse: collapse;
            border-spacing: 0;

            width: 100%;
            max-width: 100%;
            background-color: initial;
            counter-increment: table;

            td,
            th {
                padding: 0.5em 1em;
            }

            th {
                border: 0.2rem solid #ddd;
                font-weight: 700;
                text-align: left;
                vertical-align: bottom;
            }

            td {
                vertical-align: middle;
                height: 3rem;
                border: 0.1rem solid #ddd;
            }
        }

        .os-caption-container {
            padding: 8px;
            border-top: 0.1rem solid #dcdcdc;
            font-size: .9em !important;
            width: max-content;

            .os-title-label,
            .os-number {
                display: inline-block;
                padding-right: 0.25em;
                font-weight: 700;
            }

            .os-title {
                font-weight: normal;
                padding: 0;
                border-bottom: 0;
                letter-spacing: 0;
                text-transform: initial;
                font-size: .9em;
            }
        }
    }

    p {
        margin-bottom: 0.5rem;
    }

    // ul {
    //     list-style-type: none;
    //     margin: 0;
    //     padding: 0;
    //   }

    .questionclasses {
        // background-color: #777;
        // color: white;
        cursor: pointer;

    }

    .questionclassespc {
        // background-color: #777;
        // color: white;
        padding-left: 5px;
        fill: #fdbd17;
        cursor: pointer;

    }

    @keyframes scaleOut {
        from {
            opacity: 1;
            transform: scale(0.5);
        }

        to {
            opacity: 0;
            transform: scale(1.2);
        }
    }

    .questionclassespcmcq {
        // background-color: #777;
        // color: white;
        // padding-left: 5px;
        display: inline-flex;
        cursor: pointer;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background: linear-gradient(270deg, #D5A60B 0%, #FADD7A 100%);
        //position: relative;
        justify-content: center;
        align-items: center;
       // animation: scaleOut 0.8s infinite;
        z-index: 1;
        cursor: pointer;
    }

    // .questionclassespcmcq:after {
    //     content: '';
    //     height: 30px;
    //     width: 30px;
    //     border-radius: 100%;
    //     background: #D5A60B;
    //     position: absolute;
    //     animation: scaleOut 0.8s infinite;
    //     z-index: -1;
    // }


    //   .active, .collapsible:hover {
    //     background-color: #555;
    //   }

    .content {
        list-style-type: none;
        padding: 0 18px;
        display: block;
        overflow: hidden;
        // background-color: #f1f1f1;
    }
}

html {
    scroll-behavior: smooth;
}

.chapter-content-module {
    //font-family: "Roboto", sans-serif;
    //font-family: 'Be Vietnam Pro', sans-serif;
    -webkit-user-select: text;
    /* Safari */
    -ms-user-select: text;
    /* IE 10 and IE 11 */
    user-select: text;

    [data-type="title"] {
        margin: 1.2rem 0 1rem;
        font-weight: 700;
        color: #333;
    }

    p>[data-type="title"] {
        display: block;
        font-weight: 700;
        margin: 0px;
    }

    [data-type="term"],
    .bold,
    .os-title-label,
    .os-number {
        font-weight: 700;
    }

    [data-type="equation"] {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .os-equation-number {
            display: table-cell;
            width: 5%;
            vertical-align: middle;

            .os-number {
                padding: 5px;
                border: 1px solid #000;
                text-align: center;
                vertical-align: middle;
            }
        }
    }

    .note,
    [data-type="note"],
    [data-type="example"] {
        margin: 3rem 0;
        padding: 1.5rem;
        border: 0.2rem solid #dcdcdc;
        background-color: #ededed;
    }

    .os-title {
        margin-top: 0;
        margin-bottom: 1rem;
        padding: 0 1.5rem 1rem;
        border-bottom: 0.2rem solid #dcdcdc;
        color: #424242;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
    }

    p {
        margin: 1rem 0 0;
        color: #424242;
    }

    ul {
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #424242;
        list-style-type: disc;
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    ol {
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #424242;
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        list-style-type: lower-alpha;
    }

    .MathJax_Display {
        text-align: center;
        margin-top: 1em;
        position: relative;
        display: block !important;
        text-indent: 0;
        max-width: none;
        max-height: none;
        min-width: 0;
        min-height: 0;
        width: 100%;
    }

    .os-figure {
        display: table;
        margin: 3rem auto;
    }

    .os-caption-container {
        text-size-adjust: 100%;
        box-sizing: border-box;
        overflow: initial;
        display: table-caption;
        padding-top: 1rem;
        color: #424242;
        font-size: 0.8rem;
        line-height: 17px !important;
        caption-side: bottom;
        line-height: 1.7rem;
    }
}

.ckeditorsynopsis .cke_top {
    //display: none !important;
    //padding: 6px 8px 10px !important;
    padding: 12px 8px 12px !important;
    height: 100% !important;
    max-height: 60px !important;
}

.cke_bottom {
    display: none !important;
}

.cke_contents {
    //height: calc(100% - 250px) !important;
    border: none !important;
}

.cke_reset {
    border: none !important;
    height: calc(100% - 20px) !important;
}

.cke_button__image {
    display: none !important;
}

.note-highlighter1 {
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    position: absolute;
    //background: rgb(245, 245, 245);
    color: white;
    z-index: 1000;

    // box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 2px 2px 0px,
    //     rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;
    .mx-1 {
        width: 14%;
        //position: relative;
        text-align: center;
    }

    .highlighter-header {
        display: flex;
        color: black;
        /*margin-bottom: 8px;*/
        padding: 4px 0;

        .delete-tooltip,
        .close-tooltip {
            cursor: pointer;
        }

        .highlighter-color-picker {
            // display: flex;
            // flex-wrap: wrap;
            width: 100%;

            .div1 {
                display: flex;
                //width: 33%;
                padding: 2px;


            }

            .bordercss {
                font-weight: 600;
            }

            .sub-legent {
                display: flex;
                flex-wrap: wrap;
            }

            .legend {
                font-size: 12px;
                //position: absolute;
                top: 20px;
                line-height: 12px;
                // height: 12px;
                padding-left: 5px;
                text-align: left !important;
            }

            .yellow-highlighter {
                background-color: rgb(255, 255, 138);
                height: 0.75rem;
                width: 0.75rem;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: rgb(254, 210, 0);
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .green-highlighter {
                background-color: #bfd4ff;
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #55a6ff;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .grey-highlighter {
                background-color: #c0c0c0;
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #737373;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .blue-highlighter {
                background-color: #5897fb; //rgb(0,100,255);
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #5897fb;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .pink-highlighter {
                background-color: #bdd8b2;
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #bdd8b2;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .lq-highlighter {
                background-color: #74ba56;
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #74ba56;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .purple-highlighter {
                background-color: #ffc1c1;
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #ffc1c1;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .orange-highlighter {
                background-color: #ffa500;
                height: 0.75rem;
                width: 0.75rem;
                //margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #ffc1c1;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }

            .selected-highlighter-color {
                display: block;
                height: 14px;
            }
        }
    }

    .inline-note {
        display: block;
        font-weight: normal;
        // border-width: 1px;
        // border-style: solid;
        // border-color: rgb(213, 213, 213);
        border-image: initial;
        padding: 8px;
    }

    .highlighter-buttons {
        margin-top: 8px;
        display: grid;
        grid-auto-flow: column;
        overflow: visible;
        gap: 1rem;

        .save-button {
            background-color: #fdbd17;
            border-radius: 0.2rem;
            border-style: none;
        }

        .cancel-button {
            background-color: rgb(255, 255, 255);
            border-radius: 0.2rem;
            border-style: none;
        }
    }
}

.show-highlighter {
    display: flex;
}

.hidden-highlighter {
    display: none !important;
}

.cke_button__bgcolor_icon {
    //background: url(icons.png?t=MB2G) no-repeat 0 -408px!important;
}

.html-content .cke_toolgroup .cke_button__bgcolor_icon {
    background: url('../../../../assets/images/highlight_marker.png') !important;
}






@media screen and (max-width: 1024px) {
    .cke_top {

        max-height: 70px !important;
    }

    .legend {
        font-size: 10px;

    }
}

@media screen and (max-width: 955px) {
    .cke_top {
        max-height: 85px !important;
    }
}

@media screen and (max-width: 900px) {
    .cke_top {
        max-height: 95px !important;
    }

    .legend {
        font-size: 10px;

    }
}

@media screen and (max-width: 825px) {
    .ckeditorsynopsis .cke_top {
        max-height: 120px !important;
    }

    .legend {
        font-size: 10px;

    }
}

@media screen and (min-width: 1280px) {
    .ckeditorsynopsis .cke_top {

        max-height: 45px !important;
    }

    .legend {
        font-size: 10px;

    }
}

@media (max-width: 1366px) {
    .legend {
        font-size: 9px !important;

    }
}