.textbook-hint {
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: absolute;
    background: #ffcb49;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;

    .hint-content {
        max-width: 350px;
    }

    .hint-content > p {
        margin: 0px;
    }

}

.show-hint {
    display: flex;
}

.hidden-hint {
    display: none !important;
}
