
    .test-modal-body {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0 !important;
        overflow-x: hidden !important;
        .chatBot {
            position: inherit;
        }

        .chatBot .chatBotContainer {
            width: 100%;
            height: 420px !important;
            overflow-y: hidden;
            border-radius: 0.3rem;
            background-color: #fff;
        }

        .chatBot .chatBotContainer .chatContainer {
            height: 74%;
        }

        .chatBot .chatBotContainer .inputContainer {
            margin-top: 0;
        }
    }
