.question-textarea {
    width: 70%;
}

.question-img,
.answer-img {
    display: block;
    max-width: 300px;
    max-height: 150px;
}

.answer-img,
.answer-desc {
    display: none;
}
