.navlinks {
  list-style-type: none;
  clear: none;
  max-height: none;
  margin-bottom: unset;
  padding-left: unset;
  position: relative;
}
.hamburger {
  // removes default border on button element
  border: 0;
  height: 40px;
  width: 50px;
  padding: 0.5rem;
  border-radius: 50%;
  background-image: url(../../../assets/images/menu-icon.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position: center;
  background-size: 30px;
  background-color: #4252AF;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  // positions the icon to the right and center aligns it vertically
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  display: none;
  &.closeHamburger {
    // background-color: #f5f5f5;
    background-image: url(../../../assets/images/menu-close-icon.svg);
    z-index: 203;
    margin-top: 3px;
  }
  &:hover {
    background-color: #2642af;
  }
}

@media screen and (max-width: 1024px) {
  .hamburger {
    display: block;
    &.closeHamburger {
      top: 20px;
    }
  }

  .navlinks {
    display: none;
  }

  .navlinks {
    &.expanded {
      display: block;
      z-index: 201;
    }
    position: absolute;
    right: 0px;
    top: 0;
    background-color: #fff;
    border-bottom: 2px solid #4252AF;
    z-index: 1;
    padding-top: 40px;
    width: 100%;
    .navlink {
      display: block;
      line-height: 0;
      a {
        color: #000;
        padding: 23px 30px 20px 15px;
        text-align: right;
      }
      span {
        width: 100%;
        background-color: #ccc;
        height: 1px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  // .hamburger {
  //   top: 70%;
   
  // }
}
