.friend-thumb {
  float: left;
  margin-right: 10px;
  width: 90px;
  overflow: hidden;
  margin-bottom: 15px;
  height: 76px;
 
  img {
    height: 100%;
    width: auto;
  }
}

.friend-text {
  float: left;
  width: calc(100% - 100px);

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    line-height: 1.25em;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 50px;
    font-size: 14px;
  }
}
