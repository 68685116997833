.text-primary {
  font-weight: 400;
  color: #007bff !important;
  font-size: 30px;  
  margin-bottom: 7px;
}
.text-danger {
  font-weight: 400;
  color: #dc3545 !important;
  font-size: 30px;  
  margin-bottom: 7px;
}

.w3-border{
    border: none!important;
    background-color: #d2d2d2;
}

.w3-blue{
    color: #fff!important;
    background-color: #2196F3!important;
    height: 10px;
}

.w3-red{
    height: 10px;
    color: #fff!important;
    background-color: #de3434!important;
}

.text-muted{
    font-weight: 300;
    color: #303030!important;
    font-size: 14px;
}