.bottom-icon {
  position: relative;
  display: inline-block;
  margin: 0;
  align-items: center;
  padding: 5px 0;

  a {
    float: left;

    img {
      margin-right: 10px;
    }
    .iconSize{
      height: 22px;
    }
  }

  label {
    font-weight: 600;
    font-size: 16px;
    color: #4d4d4d;
    line-height: 1.2em;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &:hover {
    .rating-box {
      visibility: visible;
    }
  }

  .rating-box {
    visibility: hidden;
    width: 280px;
    padding: 6px 15px;
    background-color: #f5f5f5;
    color: #4d4d4d;
    text-align: left;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: auto;
    left: -20%;
    bottom: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
    
    &::before {
      content: "";
      position: absolute;
      bottom: auto;
      top: 100%;
      left: 50%;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
      @media screen and (max-width:767px) {
        display:none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: auto;
      top: 100%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: #f5f5f5 transparent transparent transparent;
      @media screen and (max-width:767px) {
        display:none;
      }
    }

    h4 {
      font-size: 20px;
      margin-bottom: 5px;
      line-height: 1.5;
    }

    .write-review {
      border-top: 1px solid #ebebeb;
      padding: 12px 0;
      padding-bottom: 0;
    }
    @media screen and (max-width:767px) {
      // width: 100%;
      left: 0;
      
    }
  }

  .disabled-link {
    pointer-events: none;
    color: #a2a2a2;

    img {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width:767px) {
  .bottom-icon{
    label{
      font-size: 11px;
    }
  }

}