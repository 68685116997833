.search-result {
  width: 600px;
  background-color: rgba(255,255,255,0.96);
  border-radius: 6px;
  padding: 15px;
  margin: 5px auto;
  font-size: 18px;
  label {
    color: #002b70;
    border-bottom: 1px solid #959595;
    width: 100%;
    padding-bottom: 6px;
  }

  ul {
    list-style: none;
    padding-left: unset;
    margin-bottom: unset;
    li {
      a {
        color: #000;
      }
    }
  }

  .disabled-link {
    pointer-events: none;
    color: #a2a2a2;
  }
  span{
    width: 90%;
  }
}

@media (max-width: 767px) {
  .search-result {
   width: 100%;
   font-size: 14px;
  }
  .search-box {
    width: 100% !important;
  }
}
// @media (max-width: 576px) {
//   .search-result {
//    width: 65%;
//   }
//   .search-box {
//     width: 65% !important;
//   }
// }