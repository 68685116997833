.note-highlighter {
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: absolute;
    background: rgb(245, 245, 245);
    color: white;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;

    .highlighter-header {
        display: flex;
        color: black;
        margin-bottom: 8px;

        .delete-tooltip,
        .close-tooltip {
            cursor: pointer;
        }

        .highlighter-color-picker {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .legend {
                font-size: 10px;
            }

            .yellow-highlighter {
                background-color: rgb(255, 255, 138);
                height: 1.6rem;
                width: 1.6rem;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: rgb(254, 210, 0);
                border-image: initial;
                border-radius: 2rem;
                cursor: pointer;
            }

            .green-highlighter {
                background-color: #bfd4ff;
                height: 1.6rem;
                width: 1.6rem;
                margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #55a6ff;
                border-image: initial;
                border-radius: 2rem;
                cursor: pointer;
            }

            .grey-highlighter {
                background-color: #c0c0c0;
                height: 1.6rem;
                width: 1.6rem;
                margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #737373;
                border-image: initial;
                border-radius: 2rem;
                cursor: pointer;
            }

            .blue-highlighter {
                background-color: #5897fb; //rgb(0,100,255);
                height: 1.6rem;
                width: 1.6rem;
                margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #5897fb;
                border-image: initial;
                border-radius: 2rem;
                cursor: pointer;
            }
            .pink-highlighter {
                background-color: #bdd8b2;
                height: 1.6rem;
                width: 1.6rem;
                margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #bdd8b2;
                border-image: initial;
                border-radius: 2rem;
                cursor: pointer;
            }
            .lq-highlighter {
                background-color: #b4daa4;
                height: 1.6rem;
                width: 1.6rem;
                margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #b4daa4;
                border-image: initial;
                border-radius: 2rem;
                //cursor: pointer;
            }
            .purple-highlighter {
                background-color: #ffc1c1;
                height: 1.6rem;
                width: 1.6rem;
                margin-left: 8px;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: #ffc1c1;
                border-image: initial;
                border-radius: 2rem;
                cursor: pointer;
            }

            .selected-highlighter-color {
                display: block;
                height: 14px;
            }
        }
    }

    .inline-note {
        display: block;
        font-weight: normal;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(213, 213, 213);
        border-image: initial;
        padding: 8px;
    }

    .highlighter-buttons {
        margin-top: 8px;
        display: grid;
        grid-auto-flow: column;
        overflow: visible;
        gap: 1rem;

        .save-button {
            background-color: #fdbd17;
            border-radius: 0.2rem;
            border-style: none;
        }

        .cancel-button {
            background-color: rgb(255, 255, 255);
            border-radius: 0.2rem;
            border-style: none;
        }
    }
}

.show-highlighter {
    display: flex;
}

.hidden-highlighter {
    display: none !important;
}
