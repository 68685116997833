.ai-icon {
  width: 26px;
  height: 23px;
  background-size: 100% 100%;
  margin-right: 5px;
  background-repeat: no-repeat;

  &.ic-note {
    background-image: url(../images/icon_note.svg);
  }

  &.ic-highlight {
    background-image: url(../images/icon_highlight_text.svg);
  }

  &.ic-video {
    background-image: url(../images/icon_video.svg);
  }
}
