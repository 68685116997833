.form-group {
    margin-top: 0px !important;
}

// .modal-footer {
    // padding-right: 0px !important;
// }

.note-type-label {
    margin-bottom: 0px !important;
    width: 100%;
    padding-top: 0.5rem;
}

.form-check-inline {
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
}

.note-validator {
    margin: 0px;
    color: red;
}