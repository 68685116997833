.border-grey-r {
  //border-right: 1px solid #707070;
}

.headertitle {
  font-size: 18px;
  display: flex;
  padding-bottom: 10px;  
  color: #212529;
  text-decoration: none solid rgb(33, 37, 41);
  line-height: 32px;
  font-weight: 600;
}
.rightpanel {
  padding-left: 28px; 
    margin-top: 40px;
    background: #fff;
    // border-radius: 10px
    border: 1px solid #e3e3e3;
}