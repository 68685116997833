.friends-list {

  &.disabled-section {
    pointer-events: none;
    color: #a2a2a2;

    img {
      opacity: 0.7;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 5px;
  }
}
