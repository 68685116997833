.accordion {
  padding: 0;
  margin: 0 auto;

  &:hover{
      overflow-y: auto !important;      
      .accordion-item:hover{
          .accordion-item-content{
            height: auto;
          }
      }
  }

  .accordion-item {
    padding: 0 0.3em;
    background-color: transparent;
    background-image: none;
    height: auto;
    cursor: pointer;

    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      color: #000;
      padding: 0.6em 0;
    }

    .accordion-item-content {
      height: 45px;
      overflow: hidden;
      transition: all 0.25s;
      p{
          font-size: 14px;
          margin-bottom: unset;
      }
    }
  }
}
// .accordionHeight:hover{overflow-y:scroll;}
// .accordion:hover .accordionItem:hover .accordionItemContent{height: 6em;}
// .accordionItemContent, .accordion:hover .accordionItemContent {height: 25px; overflow: hidden; transition: height .25s;}
// .accordion {padding: 0; margin: 0 auto;}
// .accordion .accordionItem {padding: 0 0.3em;color: #eee;}
// .accordion h1 {margin: 0; font-weight: 500; font-size: 14px; color: #000; padding: 0.6em 0;}
// .accordion p{font-weight: 400; font-size: 14px; color: #000; margin-bottom: 4px;}
// .accordion .accordionItem {background-color: transparent; background-image: none ;}