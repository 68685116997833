.anyProblem {
  width: 100%;
  height: 100%;
  background-color: #ECF1FE;
  height: 100%;
  // position: absolute;
  // right: 10px;
  //bottom: 10px;
  z-index: 10;

  .buttonContainer {
    // width: 100%;
    //height: 50px;
    position: absolute;
    bottom: 0;
    text-align: right;
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #4252AF; // #fdbd17;
    cursor: pointer;
    // -webkit-box-shadow: 0px 0px 15px 0px #959da593;
    // -moz-box-shadow: 0px 0px 15px 0px #959da593;
    // box-shadow: 0px 0px 15px 0px #959da593;

    .minimized-view-icon {
      opacity: 1;
      transition: 0.2s ease-out;
    }

    .minimized-view-text {
      color: #fff;
      margin-left: 8px;
      opacity: 1;
      transition: 0.2s ease-out;
      transition-delay: 0.1s;
      white-space: nowrap;
    }

    .minimized-view-cross-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transition: 0.2s ease-out;
      transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      transition-delay: 0s;
      opacity: 0;
    }
  }

  .headercontainer {
    height: 50px;
  }

  .warningMsgContainer {
    background-color: rgb(245, 245, 136);
    font-size: 16px;
    padding: 3px;
    margin: 5px 15px 0 15px;
    border: 1px solid rgb(245, 245, 136);
    border-radius: 10px;
  }
  .chatBotContainer {
    width: 100%;
    height: calc(100% - 0px);
    overflow-y: hidden;
    background-color: #ECF1FE;
    display: inline-table;
    // margin-top: -15px;

    .header {
      width: 100%;
      height: 50px;
      background-color: #4252AF;
      color: #fff;
      text-align: left;
      font-size: 24px;
      padding-left: 10px;
      align-items: center;


      .closeButton {
        border: none;
        outline: none;
        background: transparent;
        position: absolute;
        font-size: 16px;
        right: -8px;

        [class^="ico-"],
        [class*=" ico-"] {
          font: normal 1em/1 Arial, sans-serif;
          display: inline-block;
        }

        .ico-times:before {
          content: "\2716";
          //   color: #000000;
        }
      }
    }

    .chatContainer {
      height: calc(100% - 175px);
      overflow-y: auto;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 14px;
      -webkit-user-select: text;
      /* Safari */
      -ms-user-select: text;
      /* IE 10 and IE 11 */
      user-select: text;



      .cautionText {
        font-size: 12px;
        font-style: italic;
        line-height: 1.1;
        padding-top: 10px;
      }

      .mainText {
        font-weight: 700;
        padding-bottom: 10px;
      }

      .loaderContainer {
        background-image: url("../../../assets/images/loading.gif");
        background-repeat: no-repeat;
        height: 30px;
        background-color: #fff;
        margin-top: -30px;
        z-index: 1;
        position: relative;
      }

      .uploadedImg {
        text-align: right;

        img {
          max-width: 600px;
          opacity: 0.8;
        }
      }

      div:first-of-type {
        .questionContainer {
          span {
            background-color: #fff;
          }
        }
      }
    }

    .chatContainerWOP {
      height: calc(100% - 280px);
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 14px;
      -webkit-user-select: text;

      /* Safari */
      -ms-user-select: text;
      /* IE 10 and IE 11 */
      user-select: text;

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        height: calc(100% - 185px);
      }

      .cautionText {
        font-size: 12px;
        font-style: italic;
        line-height: 1.1;
        padding-top: 10px;
      }

      .mainText {
        font-weight: 700;
        padding-bottom: 10px;
      }

      .loaderContainer {
        background-image: url("../../../assets/images/loading.gif");
        background-repeat: no-repeat;
        height: 30px;
        background-color: #fff;
        margin-top: -30px;
        z-index: 1;
        position: relative;
      }

      .uploadedImg {
        text-align: right;

        img {
          max-width: 600px;
          opacity: 0.8;
        }
      }

      div:first-of-type {
        .questionContainer {
          span {
            background-color: #fff;
          }
        }
      }
    }

    .chatTextContainer {
      // width: 100%;
    }

    .questionContainer {
      text-align: right;
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-right: 5px;


      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
        margin-top: 23px;
      }

      .defaultbg {
        background-color: #d8e0f3;
        color: #000;
        padding: 10px 15px;
        border-radius: 16px 0 16px 16px;
        display: inline-block;
        text-align: left;
        width: fit-content;



        img {
          max-width: 600px;
          opacity: 0.8;
        }

      }

      .redbg {
        border: 2px solid #F3CCCA !important;
        background: #FEE9E8 !important;
        padding: 5px;
        border-radius: 16px 0 16px 16px;
        display: inline-block;
        text-align: left;
        width: fit-content;

      }

      .greenbg {
        border: 2px solid #C9F1C9 !important;
        background: #EBFBEB !important;
        padding: 5px;
        border-radius: 16px 0 16px 16px;
        display: inline-block;
        text-align: left;
        width: fit-content;

      }

      .imageQuestion {
        max-height: 150px;
        max-width: 90%;
      }

      .chatUserPic {
        border-radius: 100%;
        height: 40px;
        width: 40px;
        padding: 9px;
        border: 1px solid #818181;
        text-align: center;
        margin-right: 10px;
        font-weight: 700;
      }
    }

    .answerContainer {
      text-align: left;
      margin-top: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .links {
        background-color: #FEE9E8;
        padding: 2px 5px 2px 5px;
        cursor: pointer;
        margin-bottom: 5px;
        width: max-content;

        img {
          width: 50px;
          height: 50px;
        }
      }

      .defaultqbg {
        // background-color: #e8ffd5;
        background-color: #ededed;
        color: #000;
        padding: 10px 15px;
        border-radius: 0px 16px 16px 16px;
        display: inline-block;
        text-align: left;
        width: fit-content;

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
          margin-top: 5px;
        }
      }

      .correctincorrectqbg {
        background-color: #ededed;
        color: #000;
        padding: 10px 15px;
        border-radius: 0px 16px 16px 16px;
        display: inline-block;
        text-align: left;
        width: fit-content;
      }

      .chatLogoPic {
        border-radius: 100%;
        height: 40px;
        width: 40px;
        padding: 9px;
        border: 1px solid #818181;
        text-align: center;
        margin-right: 10px;
        font-weight: 700;
      }

      .flowChartContainer {
        width: 400px;
        height: 400px;
      }
    }

    .inputContainer {
      float: left;
      display: flex;
      align-items: flex-start;
      gap: 14px;
      margin-top: 15px;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      height: 150px;


      .searchInput {
        float: left;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 20px;
        display: flex;
        align-items: center;
        //  padding-left: 10px;
        position: relative;
        width: 99%;
      }



      .Otherbtns {
        // display: flex;
        display: none;
        position: relative;
        float: left;
        top: 80%;
      }

      .search-aiPlato {
        width: 130px;
        border-left: 1px solid #ccc;
        border-radius: 0 !important;
        position: absolute;
        left: calc(100% - 276px);
        transition: all 0.25s;

        &:focus {
          width: calc(100% - 200px);
          left: 45px;
          border-left: 0;
          z-index: 1;
        }
      }

      input {
        border: 0;
        outline: 0;
        background: #fff;
      }

      .labels {
        padding: 10px;
        border-radius: 30px;
        // background: transparent;
      }
    }

    .disableInput {
      pointer-events: none;
      opacity: 0.5;
    }

    .sendButton {
      cursor: pointer;
      width: 35px;
      padding-top: 3px;

      img {
        width: 100%;
      }
    }

    .uploadImgHolder {
      float: left;

      .imageUploadInput {
        position: absolute;
        visibility: hidden;
      }

      .browseImageBtn {
        border: 1px dotted #4252AF;
        border-radius: 100%;
        padding: 8px 10px;
        font-weight: 500;
        color: #4252AF;
        cursor: pointer;
        margin: 0;
        text-wrap: nowrap;
      }

    }

    .botBtn {
      background: transparent;
      padding: 0;
    }

    .botBtns {
      width: 44px;
      height: 44px;
      background-color: #fff;
      border-radius: 100%;
      border: 1px dotted #4252AF;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.3s;
      position: relative;
      cursor: pointer;
      margin: 0;
    }

    .botBtns:hover {
      background-color: #fff;
    }

    .botBtns img {
      height: 30px;
      filter: invert(35%) sepia(11%) saturate(3452%) hue-rotate(194deg) brightness(91%) contrast(96%);
      /*#4252AF*/
    }
  }

}

.divHeader {
  float: left;
  margin-top: 10px;

  img {
    float: left;
    width: 50px;
    padding-left: 15px;
    margin-right: 15px;
    padding-top: 6px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    left: 10px;

    img {
      padding-top: 0px;
    }
  }
}

.txt {
  float: left;
}

.divClose {
  margin-top: 10px;
  float: right;
  color: gray;
  padding-top: 6px;
  cursor: pointer;
  margin-right: 10px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
     margin-top: 0px;
  }
}

.divWOPGuideBtns .equationEditLabel label {
  font-size: 10px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    font-size: 10px;
    width: 100px;
  }
}

.divWOPGuideBtns {
  z-index: 11;
  top: 5px;
  position: absolute;
  left: 35px;
  width: 100%;

  .equationEditLabel {
    padding-top: 1px;
    font-weight: 600;
    float: left;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      // padding-top: 10px !important;
      float: left;
      width: 90px;
    }
  }

  #dibWOPGBtns button {
    width: 25px;
    margin-right: 15px;
    margin-left: 20px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
      width: 10px;
    }
  }

  .evaluate {
    position: absolute;
    margin-right: 10px;
    right: 120px;
    height: 30px;
    background-color: #4252AF;
    color: #fff;
    border: 1px solid transparent;
    font-weight: normal;
    border-radius: 5px;
    font-size: 1rem;
  }

  .evaluate:hover {
    position: absolute;
    margin-right: 10px;
    right: 120px;
    height: 30px;
    background-color: #4252AF;
    color: #fff;
    border: 1px solid transparent;
    font-weight: normal;
    border-radius: 5px;
    font-size: 1rem;
  }
}

.rightAlign {
  text-align: right;
  padding-right: 120px;
}

.uploadBtnLabel {
  display: flex;
  align-items: center;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
  .anyProblem {
    .chatBotContainer {
      height: calc(100vh - 200px) !important;

      .chatContainer {
        height: calc(100vh - 375px) !important;
      }
    }
  }


  .divWOPGuideBtns {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
  }

  .boardBtns-support {
    height: 22px;
    margin-top: 17px !important;
  }

  .divWOPGuideBtns .equationEditLabel-upload,
  .equationEditLabel-scan {
    margin-top: -17px !important;
  }
}

@media screen and (max-width:768px) {
  .wrs_handWrapper {
    display: none;
  }

  .wrs_formulaDisplayWrapper {
    margin-right: 0 !important;
  }

  .wrs_toolbar {
    overflow-x: auto;
  }

  .wrs_header {
    overflow: visible !important;
  }

  .wrs_panelContainer {
    overflow: scroll !important;
    display: table;
  }

  .anyProblem {
    .chatBotContainer {
      .chatContainer {
        height: calc(100vh - 165px);
      }
    }

    .ck-editor__editable {
      overflow: scroll;
    }
  }
}

.wirisIcon {
  position: absolute;
  top: 10px;
  z-index: 1;
  pointer-events: none;
  background: #ecf1fe;
  color: rgb(66, 82, 175);
  left: 6px;
  width: 25px;
}

.divLikeDislike {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 5px;
  bottom: -15px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
    bottom: -22px;
  }
}

.ck-tooltip {
  display: none !important;
}