.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.anyprobApp {
  width: 45%;
  margin-top: 5%;
  border: gray solid 2px; 
  position: absolute;
  z-index: 100;
  }
  
  .btnAskAiPlato { position: absolute;  bottom: 15%;}
   
  .animate {
    animation: fadeIn 500ms ease-in forwards;
  }
  
  
@keyframes fadeIn {
  from {
    transform: translateX(250px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#submitFeedback  {
  top: 25%;
  padding:0px 0px 0px 0px;
  text-align: center;
  margin:0px;
  width: 90px;
  background: #4252AF;
  z-index:15;
  border-radius: 5px 5px 0px 0px;
  -moz-transform:rotate(-90deg);
  -ms-transform:rotate(-90deg);
  -o-transform:rotate(-90deg);
  -webkit-transform:rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  right: 0px;  
}

#submitFeedback a {
  color:#fff;
  display:inline-block;
  line-height:40px;
  text-decoration: none;
}