.modal-body h2 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.modal-body h1 {
    font-size: 25px;
    font-weight: 400;
    padding-left: 8px;
}

.modal-body .rating {
    margin: 0px;
}

.modal-body .col {
    height: fit-content;
    padding: 10px;
}

.form-group {
    margin-bottom: 0px !important;
    margin-top: 1rem;
}

.review-modal .modal-body .slidecontainer {
    flex-basis: 120px;
}
