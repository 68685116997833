@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.curriculum-content-card {
    border-bottom: 1px solid rgb(209, 209, 209);  
    .font-14{
        font-size: 14px;
    }
    .bottomborder {
        border-bottom: 1px solid rgb(209, 209, 209);
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .titledata {
        border-bottom: none;
        font-family: 'Open Sans';
        font-size: 20px;
        color: #212529;
        text-decoration: none solid rgb(33, 37, 41);
        line-height: 32px;
        font-weight: 600;
    }

    .content-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        a {
            // transition: all 200ms linear;
            // color: black;
            // max-width: 380px;
            // padding: 10px;
            // margin-bottom: 8px;
            // margin-right: 15px;
            color: black;
            max-width: 265px;

            .video-thumb {
                float: left;
                margin-right: 10px;
                width: 120px;
                overflow: hidden;

                img {
                    width: inherit;
                }
            }

            .content-card-text {
                float: left;
                width: calc(100% - 130px);

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 12px;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                p {
                    line-height: 1.3em;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: .4rem;
                }

                .interactive-video-icon {
                    position: relative;

                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        a:hover {
            // box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px,
            //     rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
            text-decoration: none;
        }
    }

    .alpha-data-label {
        font-size: 10px;
        font-weight: bold;
        color: #aeaeae;
        text-transform: uppercase;
        margin-right: 16px !important;
    }
}
.border-bottom-last:last-child {
    border-bottom: none;
  }


.cardsdata {
    width: 295px !important;
    height: 350px !important;
    border-radius: 10px !important;
    fill: #ffffff !important;
    border: none !important;
    box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05) !important;
    padding: 10px;
    margin-bottom: 35px;
    margin-right: 10px;
}

.cardsimage {
    width: 275px !important;
    //height: 202px !important;
    max-height: 200px;
    border-radius: 10px !important;
    fill: #dadada !important;
}

.cardstext {
    font-size: 16px !important;
    color: #212529 !important;
    text-decoration: none solid rgba(33, 37, 41, 0.9);
    line-height: 28px !important;
    margin-bottom: 0.60rem !important;
}

.cardstitle {

    font-size: 18px !important;
    font-weight: bold !important;
    color: #212529 !important;
    text-decoration: none solid rgb(33, 37, 41);
    line-height: 32px !important;
    margin-bottom: 0.60rem !important;
}

.cardsbody {
    padding: 0 !important;
}

.parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .cardsdata {
        width: 300px !important;
        height: 400px !important;
    }

    .cardsimage {
        width: 280px !important;
        height: 240px !important;
    }

}

@media (max-width: 767px) {
    .cardsdata {
        width: 300px !important;
        height: 380px !important;
        margin-bottom: 25px;
    }

    .cardsimage {
        width: 280px !important;
        height: 220px !important;
    }

}

