.dashboarcards {
  //padding-top: 5px;   
  border-bottom: 1px solid rgb(209, 209, 209);

  h4 {
    //border-bottom: 1px solid rgb(209, 209, 209);
    padding-bottom: 15px;
  }

  .betaImage {
    //background-image: url(../../../assets/images/beta-1.png);
    position: fixed;
    width: 27px;
    height: 40px;
    right: 10px;
    //background-repeat: no-repeat;
    top: 10px;
  }

  .questions {
    //display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .borderbottom {
      border-bottom: 1px solid rgb(209, 209, 209);
    }



    a {
      //transition: all 200ms linear;
      color: black;
      // max-width: 265px;
      // padding: 10px;
      // margin-bottom: 15px;
      // margin-right: 15px;
      text-decoration: none;
      width: 100%;

      // @media (min-width:992px) {
      //   width: calc(50% - 25px);
      // }

      // @media (min-width:1281px) {
      //   width: calc(33.33% - 34px);
      // }

      // @media (min-width:1800px) {
      //   width: calc(25% - 37.5px);
      // }

      .challenge-text {
        float: left;
        width: calc(100% - 130px);

        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 12px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #212529;
          line-height: 26px;
        }

        p {
          line-height: 1.3em;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 60px;
          margin-bottom: 0px;
          font-size: 15px;
          color: #212529;
          max-height: 100px;
        }


      }

      .alpha-data-label {
        font-size: 10px;
        font-weight: bold;
        color: #aeaeae;
        text-transform: uppercase;
        margin-right: 16px !important;
      }

    }

    // a:hover {
    //    box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    //   text-decoration: none;
    // }
  }

  .threedots {
    box-shadow: "none";
    text-decoration: none;
    cursor: pointer;
  }

  .video-thumb {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    float: left;
    margin-right: 10px;
    width: 120px;
    overflow: hidden;

    img {
      width: inherit;
      border-radius: 10px;
    }
  }

  .video_thumbnail {
    width: 120px;

    img {
      width: inherit;
    }
  }

  .threedots:after {
    content: "\2807";
    font-size: 20px;

  }

  .dropdown-menu {
    min-width: 0rem;
    padding: 0
  }

  .dropdown-item {
    padding: 5px !important;
    margin-bottom: 1px !important;
  }

  .cards,
  .fullcard {
    //transition: all 200ms linear;
    //color: black;    
    //max-width: 380px;    
    //height: 230px;
    //padding: 10px;
    margin-bottom: 30px;
    //border-radius: 10px;
    //margin-right: 10px;
    //float: left;
    //width: calc(100% - 130px);

    //box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    &:hover {
      //box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
      text-decoration: none;
    }
  }

  .fullcard {
    .col {
      border: 1px solid #c1c1c1;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
    }

    .col:last-child {
      border: 0;
    }
  }

  .cards1 {
    transition: all 200ms linear;
    color: black;
    //width: 260px;
    height: 230px;
    padding: 10px;
    margin-bottom: 15px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 0px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
      text-decoration: none;
    }
  }

  .ch-title {
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .headertext {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .wrap {
      width: 85%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .challengeTxt {
      line-height: 1.2;
      width: 85%;
      padding-top: 12px;

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }
    }

    .linkbutton {
      white-space: nowrap;
      cursor: pointer;
      text-decoration: none solid rgb(33, 37, 41);
      font-size: 12px;
      color: #0080ff;
      line-height: 32px;
      text-align: right;

      &:hover {
        text-decoration: underline;
      }
    }


    .wrapborder {
      width: 98%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-bottom: 1px solid #ccc;

    }

    .hide {
      display: none;
    }
  }

  .challenge-status {
    padding-top: 5px !important;

    .status-title {
      font-size: 11px;
      text-decoration: none;
      font-weight: 600;
      color: #28a745;
      // text-align: right;

      .left-align {
        text-align: left;
      }
    }

    .status-title-source {
      font-size: 11px;
      text-decoration: none;
      color: #28a745;

      .left-align {
        text-align: left;
      }
    }

    .user-title {
      font-size: 12px;
      text-decoration: none;
      font-weight: 600;
    }


  }

  .questionfonts {
    font-size: 12px !important;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    // margin-right: -15px;
    // margin-left: -15px;
  }


  .questionfontsmulti {
    font-size: 12px !important;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .textquestion {
    cursor: default;
    display: inline-block;
    position: relative;
    font-size: 12px;

    &:hover .iframe-preview {
      opacity: 1;
      transform: translateX(-50%) translateY(0) scale(1);
    }


  }

  .questionfonts {
    font-size: 12px !important;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    // margin-right: -15px;
    // margin-left: -15px;
  }

  .iframe-preview {
    transition: .2s ease-in-out opacity, .2s ease-in-out transform;
    opacity: 0;
    position: absolute;
    top: 10%;
    left: 50%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .5);
    width: 380px;
    padding-right: 30px;
    height: 400px;
    border: 1px solid #fff;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    transform-origin: center bottom;
    transform: translateX(-50%) translateY(10px) scale(.9);
    background-color: #fff;
    z-index: 9999;

    iframe {
      transform: scale(.2);
      transform-origin: 0 0;
    }
  }

}


.onrenderitem-example {

  .deni-react-treeview-item-container {

    .icon-and-text {
      cursor: default !important;
    }

    .treeview-item-example {
      display: flex;
      flex: 1;

      .treeview-item-example-text {
        flex: 1;
      }

      .actionButton {
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.5;

        &.trash {
          color: #ff6666;
        }

        &.edit {
          color: #006699; //#00cc44;
          margin-top: 1px;
        }
      }
    }

    &:hover {
      .actionButton {
        visibility: visible;
        opacity: 1;
      }
    }

  }
}

.ch-cardsdata {
  width: 380px !important;
  height: 350px !important;
  border-radius: 10px !important;
  fill: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05) !important;
  padding: 10px;
  margin-bottom: 35px;
  margin-right: 15px;
  margin-left: 15px;
}

.ch-cardsimage {
  width: 360px !important;
  height: 200px !important;
  border-radius: 10px !important;
  fill: #dadada !important;
}

.ch-cardsbody {
  padding: 0 !important;
}

.ch-cardstext {

  font-size: 15px !important;
  color: #212529 !important;
  text-decoration: none solid rgba(33, 37, 41, 0.9);
  line-height: 28px !important;
  margin-bottom: 0.60rem !important;
}

.ch-cardstitle {
  font-size: 17px !important;
  font-weight: bold !important;
  color: #212529 !important;
  text-decoration: none solid rgb(33, 37, 41);
  line-height: 32px !important;
  margin-bottom: 0.60rem !important;
  display: flex;
  justify-content: space-between;

}

.ch-cardsdatamulti {
  width: 240px !important;
  height: 330px !important;
  border-radius: 10px !important;
  fill: #ffffff !important;
  border: none !important;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05) !important;
  padding: 10px;
  margin-bottom: 25px;
  // margin-right: 15px;
  // margin-left: 15px;
}

.ch-cardsimagemulti {
  width: 220px !important;
  height: 180px !important;
  border-radius: 10px !important;
  fill: #dadada !important;
}

.borderbotom {
  border-bottom: 1px dotted rgb(209, 209, 209);
}

.border-bottom-last:last-child {
  border-bottom: none;
}

.uppercards {
  // height: 95%;
  // margin: 10px;
  // display: flex;
  // overflow-x: unset;
  // overflow-y: unset;
  // border-radius: 10px;
  // flex-direction: column;
  // justify-content: flex-end;
  // background-color: #fff;
  // width: 100%;
}

.test {
  opacity: .2 !important;
}

@media (max-width: 1366px) {
  .ch-cardsdatamulti {
    margin-bottom: 25px;

  }
}

@media (max-width: 1024px) {

  // width: 380px !important;
  // height: 350px !important;
  // width: 360px !important;
  // height: 200px !important;
  .challengeTopic .headertext .wrap {
    width: 85%;

  }

  .ch-cardsdata {
    width: 300px !important;
    height: 370px !important;
    margin-bottom: 25px;
  }

  .ch-cardsimage {
    width: 280px !important;
    height: 220px !important;
  }

  .ch-cardsdatamulti {
    width: 200px !important;
    margin-bottom: 10px;
    margin-right: 15px;
    height: 280px !important;
  }

  .ch-cardsimagemulti {
    width: 180px !important;
    height: 120px !important;
  }
}

.statusContainer {
  display: flex;
  align-items: center;
  font-size: 13px;
  //margin-top: 10px;

  span {
    //position: absolute;
    right: 10px;
  }
}