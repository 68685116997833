.chatbotComponentDiv {
    height: 450px;
    width: 430px;
}

.arrowIcon {
    height: auto;
    width: auto;
}

.brainIcon {
    height: 20px;
    width: 20px;
}

.humanTutorBtnMargin {
    margin-right: 0px;
    margin-top: 15px;
}

.humanTutorBtnMarginNoMatch {
    margin-top: 15px;
}

.spSpace {
    margin-left: 10px;
}

.generativeAIBtnMargin {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 15px;
}

.learningcontenttextbookTabsFont {
    font-size: 1rem;
}

.tabDiv {
    height: 100%;
    margin-top: 30px;
}

.divLearningContent {
    display: flex;
    justify-content: space-between;
}

.divLearningContentLabel {
    width: 30%;
}

.divLearningContentControl {
    width: 70%;
}

.inputResource {
    border: 1px solid #ccc;
    background: transparent;
}

.editableContentDiv {
    width: 100%;
    height: 100%;
    outline: -webkit-focus-ring-color auto 1px;
}

.noEditableContentDiv {
    height: 100%;
    width: 100%;
}

.questionsContinerTableDiv {
    padding: 20px;
    width: 98%;
    margin-left: 5px;
    height: 463px;
    background: white;
    position: absolute;
    opacity: 0.98;
    overflow: auto;
}

.ckeditorsynopsis {
    // .cke_top {
    //     display: block !important;
    // }
    .cke_inner {
        display: block !important;
    }
}