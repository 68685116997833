.APTestPaper {

    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .container {
        height: 100%;
    }
}

.table {
    border: 1px solid lightgray;
}