.APTestPaper {
    .container {
        display: flex;
        overflow-x: hidden;
        overflow-y: hidden;
        flex-shrink: 0;
        width: 1440px;
        height: 100%;
        position: relative;
        opacity: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: undefined;
        flex-direction: undefined;
        background: rgba(255, 255, 255, 1);
    }

    
    .container_inner {
        display: flex;
        overflow-x: hidden;
        overflow-y: hidden;
        flex-shrink: 0;
        width: 1152px;
        height: auto;
        position: relative;
        opacity: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: undefined;
        flex-direction: column;
        gap: 96px;
    }

    .container_inner_1 {
        display: flex;
        width: auto;
        height: auto;
        position: relative;
        opacity: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: undefined;
        flex-direction: column;
        gap: 32px;
    }

    .container_inner_2 {
        display: flex;
        width: auto;
        height: auto;
        position: relative;
        opacity: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: undefined;
        flex-direction: column;
        gap: 16px;
    }

    .borderBottom {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #ccc;
    }

    .label {
        height: 30px;

    }

    .label .text-wrapper {
        color: #000000;
        font-family: "Open Sans-SemiBold", Helvetica;
        font-size: 22px;
        font-weight: 600;
        // left: 0;
        // letter-spacing: -1.5px;
        // line-height: 48px;
        // position: fixed;
        // top: 0;
        white-space: nowrap;
    }

    .sublabel {
        height: 28px;
        width: 100%;
        padding: 20px;

        .text-wrapper {
            color: #000000;
            font-family: "Open Sans-Regular", Helvetica;
            font-size: 26px;
            font-weight: 400;
            white-space: nowrap;
        }
    }



    .description {
        width: 100%;
        padding: 20px;

        .choose {
            color: transparent;
            font-family: "Average Sans-Regular", Helvetica;
            font-size: 14px;
            font-weight: 400;

        }

        .text-wrapper {
            color: #000000;

        }

        .span {
            color: #cf49a2
        }
    }

    .btnLightBlue {
        border-radius: 48px;
        background: #D2E1FD;
        -webkit-box-shadow: 0px 2px 2px 0px rgba(9, 16, 28, 0.24);
        -moz-box-shadow: 0px 2px 2px 0px rgba(9, 16, 28, 0.24);
        box-shadow: 0px 2px 2px 0px rgba(9, 16, 28, 0.24);
        color: #0A0E13;
        display: inline-flex;
        padding: 9px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-family: proxima-nova, sans-serif;
        font-size: 16px;
        font-style: normal;
        line-height: 22px;
        /* 137.5% */
        border: 0;

        &:hover {
            text-decoration: none;
            color: #fff;
            // background: linear-gradient(140deg, #28B6F3 5.4%, #0000D2 106.85%);
            background: #003392;
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            width: 380px;
            padding-left: 0px;
            padding-right: 0px;
        }

    }

}
.updategradetext {       
    font-style: normal;        
    // line-height: normal;
    // padding: 0 0 12px 0;  
    margin-bottom: 10px;
    max-width: 700px;
    overflow-x: auto;
  }

