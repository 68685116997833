.search-box {
  width: 600px !important;
  input {
    font-size: 24px;
    font-weight: 300;
    border-radius: 6px !important;
    padding-right: 70px;
    background-color: rgba(255,255,255,0.96) !important;
    height: 50px;
  }

  .input-group-append {
    right: 2px;
    position: absolute;
    z-index: 100;
    margin-left: -1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 12px 10px 0 10px;
    outline: none;

    &:hover, &:active, &:visited {
      outline: none;
      background-color: transparent;
      border: none;
    }

    img {
      border-left: 1px solid #ced4da;
      padding-left: 10px;
      height: 16px;
    }
  }
}
