.btn-with-icon {
  height: max-content;
  line-height: inherit;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  background-color: #4252AF; // #fdbd17;
  border: none;
  color: #fff;

  &:visited,
  &:active,
  &:focus {
    outline: none;
  }

  img {
    margin-right: 10px;
  }
  i {
    filter: invert(1);
  }
  &[disabled="disabled"],
  &:disabled {
    background-color: lightgray;
  }
}

.lessImportant {
  background-color: lightgray;
  // margin-left: 20px;
}
