.dashboard-container {
  max-width: 100% !important;
  background-color: #f3f3f3;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative !important;

  // max-width: 100% !important;
  .MuiAvatar-root {
    overflow: visible;
  }
}

.expandablecardsdiv {
  display: flex;
  position: absolute;
  z-index: 6;
  top: 50px;
  width: 100%;
  background: #fff;
  border-top: 1px solid #f5f5f5;
}

.expandmore {
  padding: 0px;
  position: absolute;
  // left: 48.65%;
  left: calc(50% - 20px);
  top: 42px;
  z-index: 6;

  .MuiIconButton-root {
    background-color: #ffffff;
    border-radius: 7px;
    height: 20px;

    &:hover {
      background-color: #ffffff;

      .MuiSvgIcon-root {
        fill: #000;
      }
    }

    .MuiSvgIcon-root {
      fill: #ccc;
    }
  }

  // .css-1eomvmf-MuiButtonBase-root-MuiIconButton-root{
  //   top: 225px;
  //   border-radius: 7px 7px 0 0 ;
  // }

}

.upcomming-content {
  // margin: 15px 10px;
  color: #000;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.progress-bar-container {
  display: flex;
  justify-content: center;
}

.cardsContainer {
  background-color: #f1f1f1 !important;
}

.middle-section {
  position: relative;

  .img-bg {
    overflow: hidden;
    // height: 688px;
    height: calc(100vh - 185px);
    // cursor: grab;

    .graph-image {
      transition: -webkit-transform 0.25s ease;
      transition: transform 0.25s ease;
      pointer-events: auto;
    }

    area {
      cursor: pointer;
    }

  }

  .search-content {
    position: absolute;
    z-index: 5;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);

    // &:hover {
    //   .search-result,
    //   .search-box {
    //     visibility: visible;
    //   }
    // }

    &.show-every-search-content {

      .search-result,
      .search-box {
        display: flex;


      }
    }

    .visible-search-component {
      visibility: visible !important;
      cursor: default;
      box-shadow: 0 4px 8px 0 rgba(60, 64, 73, 0.1), 0 0 1px 0 rgba(60, 64, 73, 0.15);

      svg {
        cursor: pointer;
      }
    }

    .search-result,
    .search-box {
      display: none;
      box-shadow: 0 4px 8px 0 rgba(60, 64, 73, 0.1), 0 0 1px 0 rgba(60, 64, 73, 0.15);
      padding-right: 0;
    }
  }

  .zoom-buttons {
    position: absolute;
    top: 60px;
    right: 10px;

    button {
      outline: none;
      border: none;
      background-color: rgb(88, 88, 88);
      height: 40px;
      width: 35px;
      padding: 10px 5px;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center;
    }

    .zoom-in {
      background-image: url("/images/zoom-in.svg");
    }

    .zoom-out {
      background-image: url("/images/zoom-out.svg");
      border-top: solid 1px white;
    }
  }

  .toggleGraphView {
    position: absolute;
    bottom: 0px;
    right: 10px;
    font-weight: 500;
    font-size: 12px;
  }

  .graph-legend {
    position: absolute;
    bottom: 80px;
    // @media screen and (max-height:800px) { bottom:140px }
    // @media screen and (max-height:750px) { bottom:170px }
    // @media screen and (max-height:700px) { bottom:200px }
    // @media screen and (max-height:650px) { bottom:250px }
    // @media screen and (max-height:600px) { bottom:300px }
    right: 10px;
    width: 174px;
    font-weight: 500;
    font-size: 12px;
    cursor: default;
    background: rgba(191, 212, 255, 0.7);
    padding: 8px 0px 7px 7px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;

    .legend-completed {
      &::before {
        content: "      ";
        margin-right: 5px;
        white-space: pre;
        background-color: #000EFF;
        font-size: 7px;
      }
    }

    .legend-mastery {
      &::before {
        content: "      ";
        margin-right: 2px;
        white-space: pre;
        background-color: #f00;
        font-size: 7px;
      }
    }
  }

  .dotted-line-legend {
    position: absolute;
    bottom: 130px;
    // @media screen and (max-height:800px) { bottom:70px }
    // @media screen and (max-height:750px) { bottom:100px }
    // @media screen and (max-height:700px) { bottom:130px }
    // @media screen and (max-height:650px) { bottom:180px }
    // @media screen and (max-height:600px) { bottom:230px }
    right: 10px;
    width: 174px;
    background: rgba(191, 212, 255, 0.7);
    padding: 0px 0px 7px 7px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .legendName {
      display: flex;
      align-items: center;
      padding-right: 12px;
      font-size: 12px;
      font-weight: 500;

      .black-dotted {
        border-bottom: 3px dashed #000;
      }

      .white-dotted {
        border-bottom: 3px dashed #fff;
      }

      .blue-dotted {
        border-bottom: 3px dashed #006CBB;
      }

      .line-width {
        height: 5px;
        width: 24px;
        margin-right: 5px;
      }
    }
  }

  .nodeNote {
    position: absolute;
    bottom: 5px;
    left: 140px;
    background: rgba(255, 255, 255, 0.7);
    padding: 2px 5px;
    font-size: 13px;
    font-style: italic;
  }

  .nodeNoteAnimation {
    position: absolute;
    bottom: 5px;
    left: 10px;
    background: rgba(255, 255, 255, 0.7);
    padding: 2px 5px;
    font-size: 13px;
    font-style: italic;
    animation: animate 1.5s linear infinite;
  }

  @keyframes animate {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }
}

// .img-bg {
//   height: 688px;
//   background-color: #c0d3fb;
//   padding: 15px;
//   position: relative;
//   background-position: center;

//   .vis-network{
//     outline: none;
//   }

//   .search-content{
//     position: absolute;
//     z-index: 5;
//     left: 50%;
//     transform: translate(-50%, 0);
//   }

//   .zoom-buttons{
//     position: absolute;
//     bottom: 70px;
//     right: 10px;

//     button{
//       outline: none;
//       border: none;
//       background-color: rgb(88, 88, 88);
//       height: 40px;
//       width: 35px;
//       padding: 10px 5px;
//       background-repeat: no-repeat;
//       background-size: 20px 20px;
//       background-position: center;
//     }

//     .zoom-in{
//       background-image: url('/images/zoom-in.svg');
//     }

//     .zoom-out{
//       background-image: url('/images/zoom-out.svg');
//       border-top: solid 1px white;
//     }
//   }

//   .graph-legend{
//     position: absolute;
//     bottom: 10px;
//     right: 10px;
//     font-weight: 500;
//     font-size: 12px;
//     cursor: default;

//     .legend-completed{
//       &::before{
//         content: "        ";
//         margin-right: 10px;
//         white-space: pre;
//         background-color: #5e7df7;
//       }
//     }
//     .legend-mastery{
//       &::before{
//         content: "        ";
//         margin-right: 10px;
//         white-space: pre;
//         background-color: #f00;
//       }
//     }
//   }

// }
// .sidebar-right-fix {
//   margin-top: 10px;
//   margin-right: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   /* float: right; */
//   background-color: #fff;
//   //width: 25vw;
//   padding: 20px;
//   /* height: 98vh; */
//   /* overflow: scroll; */
// }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}



// .MuiCardHeader-content {
//   width: 50%;
//   height: fit-content;
//   margin: auto;
// }

.MuiTypography-root {
  font-family: inherit !important;
  font-weight: 500 !important;
  //text-align: center !important;
}

.sample-data-label {
  font-weight: bold;
  color: #aeaeae;
  text-transform: uppercase;
  margin-right: 16px !important;
}

.rightPopup {
  .MuiDialog-paper {
    max-height: 100%;
    right: 0;
    top: 0;
    margin: 0;
    border-radius: 0;
    position: absolute;
    overflow-y: auto;
    height: 100vh;
    z-index: 1000;
  }

  .MuiDialogTitle-root {
    padding-top: 32px !important;
  }

  .MuiIconButton-root {
    padding: 5px !important;
    right: 4px;
    top: 4px;
  }
}

.riceLogoImg {
  opacity: 0.2;
  text-align: center;
  padding-top: 20%;

}

@media (max-width: 1366px) {
  .dashboard-container {

    // padding-left: 24px !important;
    // padding-right: 24px !important;
    max-width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .dashboard-container {

    // padding-left: 20px !important;
    // padding-right: 20px !important;
    max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  .middle-section {
    .img-bg {
      height: calc(100vh - 117px);
    }
  }

  .dashboard-container,
  .search-content,
  .nodeNote {
    display: none;
  }

  .search-content {
    width: 90%;
  }

  .bottomIconContainer {
    div {
      &:first-child {
        .bottom-icon {
          .rating-box {
            left: 0;
          }
        }
      }

      &:nth-child(2) {
        .bottom-icon {
          .rating-box {
            left: calc(100% - 190px);
          }
        }
      }

      &:last-child {
        .bottom-icon {
          .rating-box {
            left: calc(100% - 270px);
          }
        }
      }

    }
  }
}

.tableTitle {
  font-weight: bold;
  color: darkgray;
}