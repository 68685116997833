.raise-hand-content {
  display: flex;
  flex-direction: column;
  width: 600px;

  .ask-tutor {
    textarea {
      width: 100%;
      padding: 10px;
    }

    p {
      max-width: unset;
    }
  }

  .resource-img {
    max-width: 300px;
    // max-height: 150px;
    height: 150px;
    margin-left: 8px;
  }

  .search-knowledgebase-btn {
    width: fit-content;
  }

  .knowledge-base-content {
    p {
      max-width: unset;
    }

    .theta {
      margin-bottom: 0px;
    }

    .question {
      margin-right: 8px;
      color: #007bff;
      cursor: pointer;
      flex: 1;
      line-height: 1.2em;

      // &:hover {
      //     color: #0056b3;
      //     text-decoration: underline;
      // }
    }

    .questionbutton {
      padding: 6px 12px;
      display: inline;
      border-radius: 4px;
      border: 0;
      margin: 0 10px;
      background: #4252AF;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      width: auto;
      height: auto;
      box-sizing: content-box;
      cursor: pointer;
      font-weight: 500;
    }

    .titlenone {
      margin-top: 25px;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #cecece;
      margin-bottom: 20px;
    }

    .knowledgebase-question-container {
      justify-content: space-between;
      align-items: center;
      margin-top: 7px;
      margin-bottom: 7px;

      .question {
        margin: 0;
      }

      .theta {
        flex-shrink: 0;
      }

      img {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
      }
    }
  }

  .anticipatedQuestion {
    margin-right: 8px;
    color: #007bff;
    cursor: pointer;
    flex: 1;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }

  .font-16 {
    font-size: 16px;
  }
}

.modal-body {
  .answer-content {
    p {
      font-weight: bold;

      span,
      a {
        font-weight: normal;
      }
    }
  }
}

.raise-hand-button-section {
  position: relative;
  margin-left: 0 !important;
  margin-top: 25px !important;
  margin-bottom: 15px;

  .raiseBtn {
    margin-left: 15px;
    // color: #212529;
  }

  .disabled-btn {
    background-color: lightgray;
    cursor: default;
  }

  .skip-ahead-container {
    &:hover {
      .skip-ahead-list {
        visibility: visible;
      }
    }

    &.disabled {
      .skip-ahead-list {
        visibility: hidden;
      }
    }

    .skipButton {
      margin-left: 10px;
      // color: #212529;
    }

    .skip-ahead-list {
      width: max-content;
      cursor: default;
      visibility: hidden;
      position: absolute;
      z-index: 1;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      padding: 8px 8px;
      background-color: #fff;
      border-radius: 5px;
    }
  }
}

.resource {
  word-wrap: break-word;
}

.progress-bar-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  align-items: center;
}
.synopsisbuttonfixed {
  padding: 7px;
  /* background-color: #fdbd17; */
  outline: none;
  /* border-radius: 4px; */
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  right: 305px;
  cursor: pointer;
  color: #000;
  /* text-align: right; */
  padding: 11px;
  border-radius: 10px;
}
