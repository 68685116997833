.summary {
  font-size: 14px;

  .text {
    width: auto;
    padding: 20px 0 10px 0;

    a {
      text-decoration: none;
      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 7px;
        color: #000;
      }
    }

    p {
      margin-bottom: 20px;
      line-height: 1.5em;
    }

    .highlight-content {
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .video-content {
      display: flex;
      justify-content: space-between;
    }

    .delete-bookmark,
    .delete-highlight {
      cursor: pointer;
      width: 20px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
