$btnTextColor: #fff;

.button {
  // height: 30px;
  line-height: 16px;
  padding: 6px 12px;
  font-size: 14px;
  min-width: 125px;
  border-radius: 0;
  color: $btnTextColor;
  outline: none;
  border: none;

  &:hover,
  &:active,
  &:visited {
    color: $btnTextColor;
  }

  &.addinteraction {
    color:#000;
    background-color: transparent;
    padding: 0;
    color: #4252AF;
    font-size: "14px";
    font-weight: 500;
    cursor: pointer;

    &:hover,
    &:active,
    &:visited {
      text-decoration: underline;
    }
  }

  &.yellow-btn {
    background-color: #4252AF;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
  }

  &.yellow-btn-sticky {
    padding: 7px;
    background-color: #4252AF;
    outline: none;
    border-radius: 4px;
    position: fixed;
    z-index: 9;
    bottom: 9px;
    right: 170px;
    cursor: pointer;
    color: #fff;
    /* text-align: right; */
    padding: 11px;
    border-radius: 10px;

    .MuiSvgIcon-root {
      max-height: 16px;
      margin-right: 2px;
    }
  }

  &.grey-btn {
    background-color: #707070;
  }

  &.disabled-btn {
    background-color: #cccccc;
    color: #666666;
  }
}