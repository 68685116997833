.footer-menu {
  float: left;
  height: 100%;
  ul {
    float: left;
    clear: none;
    max-height: none;
    // margin: 19px auto;
    list-style: none;
    padding-left: unset;

    li {
      float: left;
      display: flex;
      vertical-align: middle;

      span {
        display: inline-block;
        width: 1px;
        background-color: #707070;
        height: 24px;
        margin-top: 4px;
      }

      a {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        font-size: 12px;
        color: #a2a2a2;

        &:hover {
          outline-width: 0;
        }
      }
    }
  }
}
@media screen and (max-width:768px) {
  .footer-menu {
    height: auto;
    float: right;
    ul{
      margin-bottom: 0;
      li{
        a{
          padding: 5px 8px;
        }
      }
    }
  }

}
