.APTestPaper {

    .threedots {
        box-shadow: "none";
        text-decoration: none;
        cursor: pointer;
        color: #1b262fde !important;
    }

    .threedots:after {
        content: "\2807";
        font-size: 20px;
        color: #1b262fde !important;

    }
}

.dueDateExceeds {
    background: lightgrey;
}