.challenges-container {
  //overflow-x: hidden;
  background-color: #f3f3f3;
  max-width: 96% !important;
}

.challenges {
  .challange-left {
    float: left;
    width: 300px;
    overflow: hidden;
    min-height: 84vh;
    padding-top: 30px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-left: 15px;
    background-color: #ebf1ff;
    color: #303030;

    .titletreeview {
      font-size: 22px;
      color: #212529;
      text-decoration: none solid rgb(33, 37, 41);
      line-height: 1;
    }

    .clear-filter {
      cursor: pointer;
      text-decoration: none solid rgb(33, 37, 41);
      font-size: 14px;
      color: #0080ff;
      line-height: 1;
      text-align: right;
      padding-top: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .challange-right {
    float: left;
    width: calc(100% - 300px);
    padding: 0px 20px 0px 20px;
    color: #303030;
  }

  .btn-primary {
    color: #fff;
    background-color: #fdbd17;
    border-color: #fdbd17;
  }

  .geeks {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 0 auto;
  }

  .geeks img {
    width: 100%;
    transition: 0.5s all ease-in-out;

    &:hover {
      transform: scale(1.25);
    }
  }

  .geeks:hover img {
    transform: scale(1.5);
  }


  .geeksthumbnail {
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin: 0 auto;
  }

  .geeksthumbnail img {
    width: 100%;
    transition: 0.5s all ease-in-out;

    &:hover {
      transform: scale(1.25);
    }
  }

  .geeksthumbnail:hover img {
    transform: scale(1.5);
  }

  .geeksthumbnailmul {
    overflow: hidden;
    margin: 0 auto;
  }

  .geeksthumbnailmul img {
    width: 100%;
    transition: 0.5s all ease-in-out;

    &:hover {
      transform: scale(1.25);
    }
  }

  .geeksthumbnailmul:hover img {
    transform: scale(1.5);
  }
  .divflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .commentcss {
    color: #1c87c9;    
    animation: blinker 1s linear infinite;

    &:hover {
      color: #fdbd17;
      text-decoration: none;
      animation: none;
    }
  }

  // @keyframes glowing {
  //   0% {
  //     background-color: #fdbd17;
  //     box-shadow: 0 0 5px #fdbd17;
  //   }
  //   50% {
  //     background-color: #fdbd17;
  //     box-shadow: 0 0 20px #fdbd17;
  //   }
  //   100% {
  //     background-color: #fdbd17;
  //     box-shadow: 0 0 5px #fdbd17;
  //   }
  // }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  @keyframes blin {
    50% {
      opacity: 0;
    }
  }

  .commentcsswithouttext {
    font-size: 14px;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 1366px) {
  .challenges-container {
    max-width: 96% !important;
  }
}

