.section-icon-text {
  margin-bottom: 8px;

  .font-12 {
    font-size: 12px;
  }
  .font-14{
    font-size: 14px;
  }
  .peers-name {
    font-size: 14px;
    line-height: 12px;
    padding-right: 0.4rem;
  }

  .progress-content {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.6rem;
  }

  .progress-update {
    flex: 1;

    label {
      color: #000eff;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 5px;
    }

    img {
      width: 100%;
    }
  }

  .clearfix {
    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }

  .last-progress {
    width: 100px;
    float: right;
    font-size: 14px;
  }

  .total-progress {
    width: 70px;
    float: right;
    font-size: 14px;
  }

  .bottom-progress {
    display: flex;
    margin-top: 8px;
    div {
      flex: 1;
      text-align: center;
      font-size: 14px;

      label {
        font-weight: 900;
        margin-bottom: unset;
      }
    }
  }
}

.topics-table {
  height: 300px;
  overflow: scroll;
  font-size: 14px;

  &.table {
    thead {
      th {
        border: none;
        background-color: #f6f6f6;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #c3c3c3;
      }
    }
  }
}
.progress_tab {
  cursor: pointer;
}

.progress-update-bars {
  .progress {
    height: 10px;
    border-radius: 0px;

    .progress-bar {
      position: relative;
      overflow: unset;
    }

    .outer {
      justify-content: space-between;
      flex-direction: row;

      .inner {
        background-color: white;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 3px;
        align-self: center;

        .inner-label {
          position: absolute;
          right: -18px;
          font-size: 9px;
          font-weight: bold;
        }
      }

      .label {
        align-self: center;
        position: absolute;
        right: -30px;
        font-size: 9px;
        color: black;
      }
    }
  }
}
.responsive-img{
  height: 300px !important;
}

.ProgresscompletionByWeekcss {
  width: 100%;
  height: 70%;
  min-height: 300px;
}
.verticalorientation{
  writing-mode:tb-rl;
    -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform:rotate(180deg);
    transform: rotate(180deg);
    white-space:nowrap;
    display:block;
    bottom:0;
    width:20px;
    height:20px;
    font-size: 16px;
    font-weight: 600;
    color: #3d3d3d;
    position: absolute;
    top:65%;
    left:1%;
}
