.reportContainer {
    width: 100%;
    height: 100%;
   // min-height: 500px;
    min-width: 100%;
    background-color: #fff;

    .challengesBarChart {
        height: 99%;
        min-height: 450px;
        margin-left: 5%;

        canvas {
            min-width: 450px;
            min-height: 430px;
        }
    }

    .problemNameHeader {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .prproblemNameHeader {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .prproblemNameTitle {
        font-size: 18px;
        font-weight: 700;
        margin-left: 10px;

    }

    .prproblemNameCaption {
        font-size: 18px;

        margin-left: 10px;

    }

    .kpiConainter {
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 55%;


        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 70px;
            background: #4252af;
            padding: 8px;
            border: 1px solid;
            border-radius: 10px;
            color: white;

            span {
                font-size: small;
                font-weight: 400;
                text-align: center;
            }
        }
    }

    .divprcorrect {
        progress {
            -webkit-appearance: none;
            border-radius: 10px;
            border: 1px solid #ccc;
            height: 10px;
        }

        ::-webkit-progress-bar {
            background-color: #fff;
            border-right: 1px solid #ccc;
        }

        ::-webkit-progress-value {
            background-color: rgb(191, 212, 255);
            border-left: 1px solid #ccc;
        }
    }

    .divprincorrect {
        progress {
            -webkit-appearance: none;
            border-radius: 10px;
            border: 1px solid #ccc;
            height: 10px;
        }

        ::-webkit-progress-bar {
            background-color: #fff;
            border-right: 1px solid #ccc;
        }

        ::-webkit-progress-value {
            background-color: rgb(255, 102, 102);
            border-left: 1px solid #ccc;
        }
    }

}


.tblMin {
    tr:nth-child(1) {
        background-color: rgba(150, 212, 212, 0.4);
    }

    tr:nth-child(2) {
        background-color: rgba(150, 212, 212, 0.4) !important;
    }

    tr:nth-child(3) {
        background-color: rgba(150, 212, 212, 0.4);
    }

    tr:nth-child(4) {
        background-color: rgba(150, 212, 212, 0.4) !important;
    }

    tr:nth-child(5) {
        background-color: rgba(150, 212, 212, 0.4);
    }
}

.expandCollpaseTableView {
    position: relative;
    z-index: 11;
    cursor: pointer;
    color: #4252AF;
    margin-top: 5px;
    width: 10%;
}

.tblMinGraph {
    width: 100%;
    height: 100%;
}

.RowSelectedCss {
    background-color: rgba(150, 212, 212, 0.4) !important;
}

progress {
    max-width: 50px;
}