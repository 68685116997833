.top-title {
  .top-title-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: 30px;
    font-weight: 300;
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-family: Open Sans;
    font-size: 30px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    line-height: 32px;
  }

  .sub-part {
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 13px;
    line-height: 1.62;
    letter-spacing: 0.25px;
  }

  .rating-button-box {
    display: flex;
    float: right;
    width: 240px;

    .ratings {
      width: max-content;
      margin: 5px 6px 0 0;
    }

    .rating-button {
      border-radius: 4px;
      // color: #212529;
    }
  }
}

.source-text {
  font-style: italic;
  color: #727178;
}

.review-modal {
  display: flex;
  flex-direction: column;
  margin: auto;

  .review-name {
    margin-bottom: 0.5rem;
    display: flex;

    h2 {
      font-size: 28px;
      font-weight: 300;
      color: #345ddf;
      margin-bottom: 5px;
      line-height: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .rating {
    width: max-content;
    margin-left: auto;
    height: 30px;
    float: unset;
  }

  .title {
    font-size: 22px;
    font-weight: 300;
    width: auto;
    float: unset;
    margin-bottom: 13px;
  }

  .write-review {
    border-top: 1px solid #ebebeb;
    padding: 7px 0 13px;
    display: flex;
    align-items: center;

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin: 3px 0 0 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 22px;
    }
  }

  .slide-container {
    width: 160px;
    font-size: 12px;
    color: #a1a1a1;
    margin-left: auto;

    input {
      -webkit-appearance: none;
      width: 100%;
      height: 3px;
      background: #707070;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    label {
      margin-bottom: unset;
    }

    .slow {
      float: left;
    }

    .fast {
      float: right;
    }
  }

  .well-paced-checkbox {
    .checkbox {
      margin-top: 10px;

      .abstractReview {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .review-text {
    width: 100%;
    border-color: #ebebeb;
    padding: 7px;
    font-size: 14px;
    color: #303030;
  }
}

.alpha-data-label {
  font-size: 10px;
  font-weight: bold;
  color: #aeaeae;
  text-transform: uppercase;
  margin-right: 16px !important;
}
