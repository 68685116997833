.progress-sidebar-card {
    position: fixed;
    top: 10%;
    height: 70%;
    width: 40%;
    right: -110%;
    transition: all ease-in-out 0.2s;
    background-color: white;
    border: 1px solid #B9B9B9;
    z-index: 20;
    overflow-y: auto;
    padding: 12px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

    .sidebar-heading {
        font-weight: bold;
    }

    .blue_backgroud {
        background-color: #EBF1FF;
        color: black;
    }

    .grey {
        background-color: rgb(248, 245, 245);
        color: black;
    }

    .green {
        color: green;
        position: relative;
    }

    .red {
        color: red;
        position: relative;
    }

    a p {
        word-break: break-all;
        white-space: normal;
    }

    .last-line-marker {
        display: flex;
        align-items: center;
        width: fit-content;
        position: absolute;
        right: 0;
        margin-right: 12px;
    }

    .sidebar-close {
        float: right;
        cursor: pointer;
    }

    .progress-sidebar-content {
        overflow-y: auto;
        height: calc(100% - 45px);

        .MJXp-display {
            margin: 0px !important;
            margin-bottom: 0.5rem !important;
        }
    }

    // ul{
    //     margin: 15px 30px;
    //     list-style-type: disc;
    //     li{
    //         margin-top: 10px;
    //         border-bottom: 0.5px solid rgb(206, 206, 206);
    //         p{
    //             &:last-child{
    //                 margin-bottom: 7px;
    //             }
    //         }
    //     }
    // }
    // .no-previous-hints{
    //     height: 100%;
    // }
    .close-button-container {
        border-bottom: 0.5px solid lightgrey;
        cursor:grabbing;
        button {
            border: none;
            outline: none;
            background: transparent;

            [class^="ico-"],
            [class*=" ico-"] {
                font: normal 1em/1 Arial, sans-serif;
                display: inline-block;
            }

            .ico-times:before {
                content: "\2716";
                color: #000000;
            }
        }
    }

    .diagnostic-question-button {
        width: fit-content;
    }

    &.show {
        right: 6%;
    }

    &.show-left {
        left: 6%;
    }


    @media only screen and (max-width: 600px) {
        &.show {
            left: 1%;
            width: 93%;
            top: 21%;
        }
    }



}