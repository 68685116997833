    .modal {
      // top: 60px !important;
    }
    .modal-body {
        background-color: #fafcfe;
        max-height: 75vh;
        // min-height: 500px;
        overflow: auto;
    }

    .graph-legend {
        position: absolute;
        bottom: 80px;
        // @media screen and (max-height:800px) { bottom:140px }
        // @media screen and (max-height:750px) { bottom:170px }
        // @media screen and (max-height:700px) { bottom:200px }
        // @media screen and (max-height:650px) { bottom:250px }
        // @media screen and (max-height:600px) { bottom:300px }
        right: 10px;
        width: 174px;
        font-weight: 500;
        font-size: 12px;
        cursor: default;
        background: rgba(191, 212, 255, 0.7);
        padding: 8px 0px 7px 7px;
        border: 1px solid rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        gap: 10px;
    
        .legend-completed {
          &::before {
            content: "      ";
            margin-right: 5px;
            white-space: pre;
            background-color: #000EFF;
            font-size: 7px;
          }
        }
        .legend-mastery {
          &::before {
            content: "      ";
            margin-right: 2px;
            white-space: pre;
            background-color: #f00;
            font-size: 7px;
          }
        }
      }
      .dotted-line-legend{
        position: absolute;
        bottom: 130px;
        // @media screen and (max-height:800px) { bottom:70px }
        // @media screen and (max-height:750px) { bottom:100px }
        // @media screen and (max-height:700px) { bottom:130px }
        // @media screen and (max-height:650px) { bottom:180px }
        // @media screen and (max-height:600px) { bottom:230px }
        right: 10px;
        width: 174px;
        background: rgba(191, 212, 255, 0.7);
        padding: 0px 0px 7px 7px;
        border: 1px solid rgba(0,0,0,0.1);
        .legendName{
          display: flex;
          align-items: center;
          padding-right: 12px;
          font-size: 12px;
          font-weight: 500;
          .black-dotted{
            border-bottom: 3px dashed #000;
          }
          .white-dotted{
            border-bottom: 3px dashed #fff;
          }
          .blue-dotted{
            border-bottom: 3px dashed #006CBB;
          }
          .line-width{
            height: 5px; width: 24px;
            margin-right: 5px;
          }
        }
      }

  .MuiTableCell-head{
    background-color: #f8f8f8 !important;
    color: #52575c!important;
    padding: 10px!important;
    font-size: 14px!important;
    font-weight: bold!important;
    text-transform: uppercase;
  }
  
.MuiPagination-root{
  padding: 14px 5px;
  .MuiButtonBase-root{
      &.Mui-selected{
        color: #ffffff ;
        border: 1px solid #164B99 ;
        background-color: #164B99 ;
      }
    
    
    
  }
}
.tableRow .MuiTableRow-root:nth-of-type(even) {
  background-color: unset !important;
}