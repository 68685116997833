$checkedColor: black;

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 1.4em;
  width: 1.4em;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid #707070;
  top: -1px;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.checkmark {
  background-color: #d66400;
  box-shadow: inset 0 0 0 2px #bfd4ff;
}
