.footer {
  height: 40px;
  // margin: 0 auto;
  margin-top: auto;
  width: 100%;
  background-color: #4d4d4d;
  display: block;
  z-index: 12;
}

.footer-logo {
  float: left;
  width: 145px;
  text-align: center;
  // margin: 16px 0;
}

.copyright-text {
  float: right;
  color: #a2a2a2;
  font-size: 12px;
  margin: 11px 20px 0 0;
  cursor: default;
}

@media  screen and (max-width:768px) {
  .footer{
    height: auto;
  }
  .footer-logo {
    width: 70px;
    img{
      width: 60px;
    }
  } 
  .copyright-text {
    margin-top: 5px;
  }
}

@media  screen and (max-width:767px) {
  .copyright-text {
    margin-top: 0;
    margin-right: 7px;
    font-size: 10px;
  }
  .footer{
    display: none;
  }
}
