.simple-video-component {
    display: flex;
    margin-bottom: 10px;

    .image-container {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .video-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .video-subtitle {
        margin-bottom: 0px !important;
    }

    .video-link-container {
        display: flex;

        .video-link {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: inherit;
            text-decoration: none;
            display: block;
        }
    }
}
